import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGiliamsC2H24 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Giliams',
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '110%',
        color: Color2,
        textDecoration: 'none',
        textTransform: 'none',
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGiliamsC2H24;
