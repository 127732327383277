import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, styled, TextField } from '@mui/material';
import HeaderGiliamsH51 from '../components/typography/HeaderGiliamsH51';
import ParagraphGillC2H19 from '../components/typography/ParagraphGillC2H19';
import { Color8 } from '../colors/Colors';
import HeaderGiliamsC2H24 from '../components/typography/HeaderGiliamsC2H24';
import TextInput from '../components/basic/TextInput';
import Countries from '../components/basic/Countries';
import InstagramSection from '../components/InstagramSection';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import RoundedButtonSignIn from '../components/basic/RoundedButtonSignIn';
import { signInActions } from '../store/signIn';
import moment from 'moment';
import './SignIn.css';
import '../components/basic/TextInput.css';
import countryCodes from '../utils/countryCodes';
import {
  faLessThanEqual,
  faListSquares,
} from '@fortawesome/free-solid-svg-icons';

const boxStyle = {
  marginTop: '22px ',
};

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitSData, setSubmitSData] = useState();
  const [emailValidate, setEmailValidate] = useState(false);
  const [instaValidate, setInstaValidate] = useState(false);
  const [facebookValidate, setFacebookValidate] = useState(false);
  const [WebsiteValidate, setWebsiteValidate] = useState(false);

  const [birthDateValidation, setBirthDateValidation] = useState(false);

  const getItem = (key) => {
    const data = typeof window !== 'undefined' ? localStorage.getItem(key) : '';
    try {
      return JSON.parse(data);
    } catch (err) {
      return data;
    }
  };
  const type = getItem('type');
  const [data, setData] = useState({
    first_name: '',
    middle_name: '',
    mobile_Code: '+1',
    whatsapp_Code: '+1',
    surname: '',
    birth_date: '',
    brand_name: '',
    franchise: false,
    gender: 'male',
    street_address: '',
    city: '',
    zip_code: '',
    country: '',
    bio: '',
    email: '',
    mobile_number: '',
    whatsapp_number: '',
    instagram_url: '',
    facebook_url: '',
    tiktok_url: '',
    website_url: '',
    whatsapp_Code_Name: '+1 Canada',
    terms: false,
  });
  console.log('🚀 ~ file: SignIn.js:75 ~ SignIn ~ data:', data);

  // First Name
  const changeNameHandler = (name) => {
    dispatch(signInActions.setFirstName(name.target.value));
  };

  // Email Address
  const changeEmailAddressHandler = (email) => {
    dispatch(signInActions.setEmailAddress(email));
  };

  // Mobile Number
  const changeMobileNumberHandler = (number) => {
    setData({
      ...data,
      mobile_number: number.target.value.slice(
        0,
        sortedArr.find((e) => e.dial_code == data.mobile_Code).phLength,
      ),
    });

    dispatch(signInActions.setMobileNumber(number.target.value));
  };

  const changeWhatsappNumberHandler = (number) => {
    setData({
      ...data,
      whatsapp_number: number.target.value.slice(
        0,
        sortedArr.find((e) => e.dial_code == data.whatsapp_Code).phLength,
      ),
    });

    dispatch(signInActions.setMobileNumber(number.target.value));
  };

  // Agree Terms
  const changeAgreeTermsHandler = (agree) => {
    dispatch(signInActions.setAgreeTerms(agree.target.checked));
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setData({ ...data, zip_code: value.slice(0, 7) });
  };
  function isValiEmail(val) {
    setEmailValidate(false);
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      setEmailValidate(true);
    } else {
      setEmailValidate(false);
    }
  }

  function isValiInsta(val) {
    setInstaValidate(false);
    let regInsta = /^(https?:\/\/){0,1}(www\.){0,1}instagram\.com/;
    if (!regInsta.test(val)) {
      setInstaValidate(true);
      dispatch(signInActions.setInstagramUrl(true));
    } else {
      dispatch(signInActions.setInstagramUrl(false));
      setInstaValidate(false);
    }
  }

  function isValiFacebook(val) {
    setFacebookValidate(false);
    let regFacebook = /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/;
    if (!regFacebook.test(val)) {
      dispatch(signInActions.setFacebookUrl(true));

      setFacebookValidate(true);
    } else {
      dispatch(signInActions.setFacebookUrl(false));

      setFacebookValidate(false);
    }
  }

  function checkUrl(string) {
    try {
      new URL(string);
      dispatch(signInActions.setWebsiteUrl(false));

      setWebsiteValidate(false);
    } catch (err) {
      setWebsiteValidate(true);
      dispatch(signInActions.setWebsiteUrl(true));
    }
  }

  const onBirthDateChange = (value) => {
    dispatch(
      signInActions.setBirthOfDate(moment(value?.$d).format('YYYY-MM-DD')),
    );
    var years = moment().diff(moment(value?.$d).format('YYYY'), 'years');
    if (years < 13) {
      setBirthDateValidation(true);
    } else {
      setBirthDateValidation(false);
    }
    setData({
      ...data,
      birth_date: moment(value?.$d).format('YYYY-MM-DD'),
    });
  };

  const sortedArr = countryCodes.sort((a, b) => {
    const codeA = a.dial_code;
    const codeB = b.dial_code;

    if (codeA > codeB) {
      return 1;
    }

    if (codeA < codeB) {
      return -1;
    }
    return 0;
  });

  let updatedPhones = sortedArr.map(function (item) {
    return {
      label: `${item.dial_code} (${item.name})`,
      value: `${item.dial_code}`,
    };
  });
  let updatedCountries = sortedArr.map(function (item) {
    return {
      label: item.name,
      value: item.name,
    };
  });

  return (
    <Box>
      <Box sx={{ maxWidth: '1142px', marginX: 'auto', marginTop: '67px' }}>
        <Box
          sx={{
            backgroundImage:
              'linear-gradient(180deg, #121212 0%, #222222 100%)',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '273px',
            width: '100%',
            zIndex: '-1',
          }}
        />
        <Box>
          <HeaderGiliamsH51 textAlign="center">
            Welcome To
            <span style={{ color: '#EAC67A' }}> The Future</span>
          </HeaderGiliamsH51>

          <Box
            sx={{ maxWidth: '390px', marginX: 'auto', marginTop: '10px' }}
            display={type === 'Creator / Influencer' ? 'block' : 'none'}
          >
            <ParagraphGillC2H19>
              Before you apply, you will need the following:
            </ParagraphGillC2H19>

            <ParagraphGillC2H19>
              ● Page Admin from a Facebook Account.
            </ParagraphGillC2H19>
            <ParagraphGillC2H19>
              ● Business/Creator Instagram account
            </ParagraphGillC2H19>
            <ParagraphGillC2H19>
              ● Facebook Page connected to Instagram account
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: `${Color8}`,
            borderRadius: '20px',
            paddingTop: '53px',
            paddingBottom: '60px',
            paddingLeft: '56px',
            paddingRight: '59px',
            marginTop: '50px',
          }}
        >
          <Grid container spacing="34px">
            <Grid item xs={12}>
              <HeaderGiliamsC2H24>Applicant Name</HeaderGiliamsC2H24>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput
                id="first-name"
                value={data.first_name}
                placeholder="First name *"
                onChange={(event) => {
                  changeNameHandler(event);
                  setData({
                    ...data,
                    first_name: event.target.value.replace(/[^a-zA-Z ]/g, ''),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                id="middle-name"
                value={data.middle_name}
                placeholder="Middle name"
                onChange={(event) => {
                  setData({
                    ...data,
                    middle_name: event.target.value.replace(/[^a-zA-Z ]/g, ''),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                id="surname"
                value={data.surname}
                placeholder="Surname"
                onChange={(event) => {
                  setData({
                    ...data,
                    surname: event.target.value.replace(/[^a-zA-Z ]/g, ''),
                  });
                }}
              />
            </Grid>

            {/*Line - 2 Brand*/}
            <Grid
              item
              xs={12}
              md={6}
              display={type === 'Brand' ? 'block' : 'none'}
            >
              <HeaderGiliamsC2H24>Brand Name</HeaderGiliamsC2H24>
              <Box style={boxStyle}>
                <TextInput
                  id="brand-name"
                  placeholder="Brand Name"
                  onChange={(event) => {
                    setData({ ...data, brand_name: event.target.value });
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display={type === 'Brand' ? 'block' : 'none'}
            >
              <HeaderGiliamsC2H24>Franchise</HeaderGiliamsC2H24>
              <Box
                style={boxStyle}
                sx={{ display: 'flex', gap: '26px', paddingBottom: '8px' }}
                className="inp-box"
              >
                <Box>
                  <label className="label">
                    Yes
                    <input
                      type="radio"
                      defaultChecked
                      name="franchise"
                      onChange={(event) => {
                        setData({ ...data, franchise: true });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Box>

                <Box>
                  <label className="label">
                    No
                    <input
                      type="radio"
                      name="franchise"
                      onChange={(event) => {
                        setData({ ...data, franchise: false });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display={type === 'Creator / Influencer' ? 'block' : 'none'}
            >
              <HeaderGiliamsC2H24>Date of Birth *</HeaderGiliamsC2H24>
              <Box
                style={{
                  borderBottom: birthDateValidation
                    ? '1px solid red'
                    : '1px solid rgba(255, 255, 255, 0.17)',
                  marginTop: '22px ',
                }}
                className="num"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    label="Birth Date"
                    className="raised-button--rounded Zdbox"
                    style={{
                      fontFamily: 'GillSansRegular',
                      fontSize: '16px',
                      lineHeight: '31px',
                      fontWeight: ' 400',
                      color: '#FFF',
                      backgroundColor: ' #373737',
                    }}
                    disableFuture
                    openTo="day"
                    views={['year', 'month', 'day']}
                    value={data?.birth_date}
                    onChange={(newValue) => {
                      onBirthDateChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              {birthDateValidation ? (
                <span
                  style={{
                    color: 'red',
                    fontFamily: 'Gill Sans ,sans-serif',
                    fontSize: '16px',
                  }}
                >
                  Date of birth must be at least 13 years old
                </span>
              ) : (
                ''
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display={type === 'Creator / Influencer' ? 'block' : 'none'}
            >
              <HeaderGiliamsC2H24>Gender *</HeaderGiliamsC2H24>
              <Box
                style={boxStyle}
                sx={{ display: 'flex', gap: '26px', paddingBottom: '8px' }}
                className="inp-box"
              >
                <Box>
                  <label className="label">
                    Male
                    <input
                      type="radio"
                      defaultChecked
                      name="radio"
                      onChange={(event) => {
                        setData({ ...data, gender: 'male' });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Box>

                <Box>
                  <label className="label">
                    Female
                    <input
                      type="radio"
                      name="radio"
                      onChange={(event) => {
                        setData({ ...data, gender: 'female' });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Box>
              </Box>
            </Grid>

            {/*Line - 3*/}
            <Grid item xs={12} md={6}>
              <HeaderGiliamsC2H24>Bio</HeaderGiliamsC2H24>
              <Box style={boxStyle}>
                <TextInput
                  id="bio"
                  placeholder="Short Bio"
                  onChange={(event) => {
                    setData({ ...data, bio: event.target.value });
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <HeaderGiliamsC2H24>Email Address *</HeaderGiliamsC2H24>
              <Box style={boxStyle}>
                <Box
                  className="inp-box"
                  style={{
                    borderBottom: emailValidate
                      ? '1px solid red'
                      : '1px solid rgba(255, 255, 255, 0.17)',
                  }}
                  sx={{ display: 'flex', flexGrow: 1 }}
                >
                  <input
                    id="email-address"
                    placeholder="Enter your email"
                    onChange={(event) => {
                      changeEmailAddressHandler(event.target.value);
                      isValiEmail(event.target.value);
                      setData({ ...data, email: event.target.value });
                    }}
                    className="inp"
                  />
                </Box>
              </Box>
              {emailValidate ? (
                <span
                  style={{
                    color: 'red',
                    fontFamily: 'Gill Sans ,sans-serif',
                    fontSize: '16px',
                  }}
                >
                  Invalid Email Address
                </span>
              ) : (
                ''
              )}
            </Grid>

            {/*Line - 4*/}
            <Grid item xs={12} md={6}>
              <HeaderGiliamsC2H24>Mobile Number *</HeaderGiliamsC2H24>
              <Box style={boxStyle} className="num" sx={{ display: 'flex' }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="Zdbox"
                  style={{ color: 'white' }}
                  options={updatedPhones}
                  onChange={(event: any, newValue: string | null) => {
                    setData({
                      ...data,
                      mobile_Code: newValue.value,
                      whatsapp_Code: newValue.value,
                      whatsapp_Code_Name: newValue.label,
                    });
                  }}
                  defaultValue={'+1 Canada'}
                  sx={{ width: '50%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Countries *" />
                  )}
                />

                <Box className="inp-box" sx={{ display: 'flex', flexGrow: 1 }}>
                  <input
                    id="mobile-number"
                    placeholder="000 000 0000"
                    type="number"
                    className="inp"
                    value={data.mobile_number}
                    onChange={(event) => {
                      changeMobileNumberHandler(event);
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key == '.' ||
                        event.key === '-' ||
                        event.key == 'e'
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <HeaderGiliamsC2H24>Whatsapp Number</HeaderGiliamsC2H24>
              <Box style={boxStyle} className="num" sx={{ display: 'flex' }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="Zdbox"
                  options={updatedPhones}
                  value={data.whatsapp_Code_Name}
                  onChange={(event: any, newValue: string | null) => {
                    setData({
                      ...data,
                      whatsapp_Code: newValue.value,
                      whatsapp_Code_Name: newValue.label,
                    });
                  }}
                  defaultValue={'+1 Canada'}
                  sx={{ width: '50%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Countries" />
                  )}
                />

                <Box className="inp-box" sx={{ display: 'flex', flexGrow: 1 }}>
                  <input
                    id="whatsapp-number"
                    placeholder="000 000 0000"
                    type="number"
                    value={data.whatsapp_number}
                    className="inp"
                    onChange={(event) => {
                      changeWhatsappNumberHandler(event);
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key == '.' ||
                        event.key === '-' ||
                        event.key == 'e'
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            {/*Line - 5*/}
            <Grid item xs={12} md={6}>
              <HeaderGiliamsC2H24>Instagram Url</HeaderGiliamsC2H24>
              <Box style={boxStyle}>
                <TextInput
                  id="instagram-url"
                  placeholder="Enter Instagram Url"
                  onChange={(event) => {
                    isValiInsta(event.target.value);

                    setData({ ...data, instagram_url: event.target.value });
                  }}
                />
              </Box>
              {console.log('insta', instaValidate)}
              {instaValidate ? (
                <span
                  style={{
                    color: 'red',
                    fontFamily: 'Gill Sans ,sans-serif',
                    fontSize: '16px',
                  }}
                >
                  Invalid Instagram url
                </span>
              ) : (
                ''
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <HeaderGiliamsC2H24>Facebook Url</HeaderGiliamsC2H24>
              <Box style={boxStyle}>
                <TextInput
                  id="facebook-url"
                  placeholder="Enter Facebook Url"
                  onChange={(event) => {
                    isValiFacebook(event.target.value);
                    setData({ ...data, facebook_url: event.target.value });
                  }}
                />
              </Box>
              {console.log('insta', facebookValidate)}
              {facebookValidate ? (
                <span
                  style={{
                    color: 'red',
                    fontFamily: 'Gill Sans ,sans-serif',
                    fontSize: '16px',
                  }}
                >
                  Invalid Facebook url
                </span>
              ) : (
                ''
              )}
            </Grid>

            {/*Line - 6*/}
            <Grid item xs={12} md={6}>
              <HeaderGiliamsC2H24>TikTok Url</HeaderGiliamsC2H24>
              <Box style={boxStyle}>
                <TextInput
                  id="tiktok-url"
                  placeholder="Enter TikTok Url"
                  onChange={(event) => {
                    setData({ ...data, tiktok_url: event.target.value });
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <HeaderGiliamsC2H24>Website Url</HeaderGiliamsC2H24>
              <Box style={boxStyle}>
                <TextInput
                  id="website-url"
                  placeholder="Enter Website Url"
                  onChange={(event) => {
                    checkUrl(event.target.value);
                    setData({ ...data, website_url: event.target.value });
                  }}
                />
              </Box>

              {WebsiteValidate ? (
                <span
                  style={{
                    color: 'red',
                    fontFamily: 'Gill Sans ,sans-serif',
                    fontSize: '16px',
                  }}
                >
                  Invalid Website url
                </span>
              ) : (
                ''
              )}
            </Grid>

            {/*Line - 7*/}
            <Grid item xs={12}>
              <HeaderGiliamsC2H24>Address</HeaderGiliamsC2H24>
              <Box style={boxStyle}>
                <TextInput
                  id="street-address"
                  placeholder="Street Address"
                  onChange={(event) => {
                    setData({ ...data, street_address: event.target.value });
                  }}
                />
              </Box>
            </Grid>

            {/*Line - 8*/}
            <Grid item xs={12} md={6}>
              <Box style={boxStyle}>
                <TextInput
                  id="city"
                  placeholder="City"
                  value={data.city}
                  onChange={(event) => {
                    setData({
                      ...data,
                      city: event.target.value.replace(/[^a-zA-Z ]/g, ''),
                    });
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box style={boxStyle} sx={{ display: 'flex' }}>
                <Box className="inp-box" sx={{ display: 'flex', flexGrow: 1 }}>
                  <input
                    id="zip"
                    value={data.zip_code}
                    placeholder="Postal / Zip Code"
                    onChange={handleChange}
                    className="inp"
                  />
                </Box>
              </Box>
            </Grid>

            {/*Line - 9*/}
            <Grid item xs={12} md={6}>
              <Box style={boxStyle}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="raised-button--rounded"
                  options={updatedCountries}
                  onChange={(event: any, newValue: string | null) => {
                    setData({ ...data, country: newValue.value });
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Countries" />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box style={boxStyle}></Box>
            </Grid>

            {/*Line - 10*/}
            <Grid item xs={12} md={6}>
              <Box
                style={boxStyle}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingTop: '14px',
                }}
              >
                <label className="container">
                  I agree with the{' '}
                  <Link
                    style={{ color: '#EAC67A', textDecoration: 'none' }}
                    to="../terms-and-conditions"
                  >
                    Terms
                  </Link>
                  ,{' '}
                  <Link
                    style={{ color: '#EAC67A', textDecoration: 'none' }}
                    to="../privacy-policy"
                  >
                    Privacy Policy
                  </Link>{' '}
                  of this platform.
                  <input
                    type="checkbox"
                    onChange={(event) => {
                      changeAgreeTermsHandler(event);
                      setData({ ...data, terms: event.target.checked });
                    }}
                  />
                  <span className="check"></span>
                </label>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                style={boxStyle}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <RoundedButtonSignIn
                  submitSData={submitSData}
                  onClickFun={() => {
                    setSubmitSData(data);
                    emailValidate
                      ? setEmailValidate(true)
                      : setEmailValidate(false);

                    birthDateValidation
                      ? setBirthDateValidation(true)
                      : setBirthDateValidation(false);
                  }}
                >
                  Submit Application
                </RoundedButtonSignIn>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={{ marginTop: '-54px', marginBottom: '-15px' }}>
        <InstagramSection showTitle={false} />
      </Box>
    </Box>
  );
};

export default SignIn;
