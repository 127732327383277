import React from 'react';
import { Box } from '@mui/material';
import Navbar from '../components/navbar/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../components/footer/Footer';

const MainLayout = () => (
  <Box>
    <Navbar />
    <Outlet />
    <Footer />
  </Box>
);

export default MainLayout;
