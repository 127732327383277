import React from 'react';
import { Box } from '@mui/material';
import Circle from '../basic/Circle';
import { Color6 } from '../../colors/Colors';
import HeaderGillC7H22 from '../typography/HeaderGillC7H22';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';

const HowItWorksBlock4 = () => {
  return (
    <Box
      sx={{
        maxWidth: '962.65px',
        marginX: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '28px',
        paddingX: { xs: '20px', lg: 0 },
        flexDirection: { xs: 'column', md: 'row' },
        columnGap: '20px',
        rowGap: '40px',
      }}
    >
      <Box sx={{ width: '300px' }}>
        <Box sx={{ marginBottom: '15px' }}>
          <Circle color={Color6} diameter="52px">
            <HeaderGillC7H22>03</HeaderGillC7H22>
          </Circle>
        </Box>

        <HeaderGiliamsC2H33>Select Influencers</HeaderGiliamsC2H33>

        <ParagraphGillC2H19>
          Find your ideal influencer match with HYYPP. Our platform invites only
          the best-suited influencers to apply for collaboration. Take your time
          reviewing their profiles, and select the ones that align with your
          brand's vision and goals. Approve the influencers you'd like to work
          with and start creating impactful campaigns together.
        </ParagraphGillC2H19>
      </Box>

      <Box sx={{ maxWidth: '441.05px', width: { xs: '100%', sm: '441.05px' } }}>
        <img
          src="images/how-it-works/block4-influencers.svg"
          alt="Select Influencers"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default HowItWorksBlock4;
