// noinspection JSValidateTypes

import React from 'react';
import { Avatar, Box } from '@mui/material';
import HeaderGillC2H16 from '../typography/HeaderGillC2H16';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Color3 } from '../../colors/Colors';
import HeaderGillC2H17 from '../typography/HeaderGillC2H17';
import HeaderGillC5H15 from '../typography/HeaderGillC5H15';

const InfluencerCard = ({ stars, review, avatar, name, type }) => {
  const starsColorArr = new Array(stars).fill(Color3);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '445px',
        height: '243px',
        borderRadius: '20px',
        backgroundColor: 'rgba(49, 49, 49, 0.82);',
        paddingTop: '34px',
        paddingBottom: '28px',
        paddingX: '37px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '7px' }}>
        {starsColorArr.map((color, index) => (
          <Box key={`star-${index + 1}`}>
            <FontAwesomeIcon
              icon={faStar}
              color={color}
              width="18px"
              height="18px"
            />
          </Box>
        ))}
      </Box>

      <Box sx={{ marginTop: '8px' }}>
        <HeaderGillC2H16>{review}</HeaderGillC2H16>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '14px' }}>
        <Box sx={{ marginRight: '15px' }}>
          <Avatar
            alt={name}
            src={`images/avatars/${avatar}`}
            sx={{ width: '58px', height: '58px' }}
          />
        </Box>

        <Box
          sx={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}
        >
          <HeaderGillC2H17>{name}</HeaderGillC2H17>

          <Box sx={{ marginTop: '-4px' }}>
            <HeaderGillC5H15>{type}</HeaderGillC5H15>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InfluencerCard;
