import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import PricingBlock1 from '../components/pricingComponents/PricingBlock1';
import PricingBlock2 from '../components/pricingComponents/PricingBlock2';
import PricingBlock3 from '../components/pricingComponents/PricingBlock3';
import InstagramSection from '../components/InstagramSection';
import ScrollAnimation from 'react-animate-on-scroll';

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <PricingBlock1 />
      <PricingBlock2 />

      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <PricingBlock3 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <Box sx={{ marginTop: '-30px' }}>
          <InstagramSection />
        </Box>
      </ScrollAnimation>
    </Box>
  );
};

export default Pricing;
