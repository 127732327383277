import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Block1 from '../components/homeComponents/Block1';
import Block2 from '../components/homeComponents/Block2';
import Block3 from '../components/homeComponents/Block3';
import Block5 from '../components/homeComponents/Block5';
import InstagramSection from '../components/InstagramSection';
import InfluencersSection from '../components/InfluencersSection';
import Block4 from '../components/homeComponents/Block4';
import CampaignsSection from '../components/CampaignsSection';
import ScrollAnimation from 'react-animate-on-scroll';
import Block6 from '../components/homeComponents/Block6';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Block1 />

      <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
        <Block2 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <Block3 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
        <Block4 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
        <Block5 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
        <CampaignsSection />
      </ScrollAnimation>

      <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
        <InfluencersSection />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <Box>
          <Block6 />
        </Box>

        <InstagramSection />
      </ScrollAnimation>
    </Box>
  );
};

export default Home;
