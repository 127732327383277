import React from 'react';
import StoreButton from './basic/StoreButton';
import { Box } from '@mui/material';

const AppsButtons = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: { xs: 'center', lg: 'flex-start' },
        alignItems: 'center',
        gap: '24px',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <StoreButton
        title="Soon on Google Play"
        icon="images/google-play-logo.svg"
        link={process.env.REACT_APP_GOOGLE_PLAY}
      />
      <StoreButton
        title="Soon on App Store"
        icon="images/app-store-logo.svg"
        link={process.env.REACT_APP_APP_STORE}
      />
    </Box>
  );
};

export default AppsButtons;
