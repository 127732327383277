import React from 'react';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import HeaderGillH20 from '../typography/HeaderGillH20';
import classes from './Navigation.css';

const Navigation = () => {
  const location = useLocation();

  const currentPage = location.pathname.replace('/', '');

  const navLinkArr = [
    'Collabs',
    'Influencer Marketing',
    'How it works',
    'Pricing',
  ];

  return (
    <Box>
      <ul
        style={{
          listStyleType: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '38px',
        }}
      >
        {navLinkArr.map((item, index) => {
          const pageLinkPre = item.toLowerCase().replace(' ', '-');
          const pageLink = pageLinkPre.replace(' ', '-');
          let linkStyle = `${classes.passiveLink} passiveLink`;

          if (currentPage === pageLink) {
            linkStyle = `${classes.activeLink} activeLink`;
          }

          return (
            <li key={`link-${index + 1}`} className={linkStyle}>
              <Link style={{ textDecoration: 'none' }} to={pageLink}>
                <HeaderGillH20>{item}</HeaderGillH20>
              </Link>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default Navigation;
