import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const ParagraphGillC2H19 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '19px',
        fontWeight: 400,
        lineHeight: '30.97px',
        color: Color2,
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default ParagraphGillC2H19;
