import React from 'react';
import { Box } from '@mui/material';
import Wrapper from '../basic/Wrapper';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';

const InfluencersBlock2 = () => {
  return (
    <Wrapper>
      <Box sx={{ paddingX: { xs: '20px', lg: 0 } }}>
        <Box
          sx={{
            maxWidth: '854px',
            marginX: 'auto',
            marginTop: { xs: '120px', lg: '162px' },
          }}
        >
          <HeaderGiliamsH71 textAlign="center">
            Extend your reach and attract more gueﬆs
          </HeaderGiliamsH71>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: { xs: '100%', lg: '1175px' },
            marginX: 'auto',
            marginTop: { xs: '50px', lg: '150px' },
            rowGap: '20px',
            columnGap: '40px',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box>
            <Box sx={{ maxWidth: { xs: '100%', lg: '398px' } }}>
              <HeaderGiliamsC2H33>
                Get access to large, exiﬆing audiences
              </HeaderGiliamsC2H33>
            </Box>

            <Box
              sx={{
                maxWidth: { xs: '100%', lg: '486px' },
                marginTop: { xs: '20px', lg: '6px' },
                marginBottom: { xs: '20px', lg: 0 },
              }}
            >
              <ParagraphGillC2H19>
                Local influencers are enthusiastic food lovers, technology
                enthusiasts, content creators, and talents who are eager to
                collaborate with your brand. They boast thousands of dedicated
                social media followers in your local area who trust their
                recommendations and are always on the lookout for new, fun, and
                exciting updates.
              </ParagraphGillC2H19>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: '100%', sm: '466px' },
              marginRight: { xs: 0, sm: '37px' },
              minWidth: { xs: 0, sm: '466px' },
              maxWidth: { xs: '466px', sm: 0 },
            }}
          >
            <img
              src="images/influencers/block2-top-countries.svg"
              alt="Top Countries"
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default InfluencersBlock2;
