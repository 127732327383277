import React from 'react';
import { Box } from '@mui/material';
import './TextInput.css';

const TextInput = ({ id, value, placeholder, onChange }) => {
  return (
    <Box className="inp-box" sx={{ display: 'flex', flexGrow: 1 }}>
      <input
        id={id}
        value={value}
        type="text"
        placeholder={placeholder}
        className="inp"
        onChange={onChange}
      />
    </Box>
  );
};

export default TextInput;
