import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import classes from './Newsletters.css';
import { Color3, Color4 } from '../colors/Colors';
import HeaderGiliamsC2H33 from './typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from './typography/ParagraphGillC2H19';

const Newsletters = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    handleOpen();
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <form onSubmit={handleSubmit}>
        <input
          id="newsletterInp"
          type="text"
          className={`${classes.newsletter} newsletter`}
          placeholder="Enter email address"
        />
        <Box sx={{ width: { xs: '300px', md: '379px' } }}>
          <input
            id="newsletterBtn"
            type="submit"
            className={`${classes.button} button`}
          />
        </Box>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '335px', sm: '420px' },
            height: '300px',
            backgroundColor: Color4,
            border: `1px solid ${Color3}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '20px 40px',
          }}
        >
          <Box
            sx={{
              backgroundImage: 'url(images/hyypp-logo.png)',
              width: '146px',
              height: '35px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />

          <Box>
            <Box>
              <HeaderGiliamsC2H33 textAlign="center">
                Welcome to HYYPP!
              </HeaderGiliamsC2H33>
            </Box>

            <Box sx={{ marginTop: '10px' }}>
              <ParagraphGillC2H19 textAlign="center">
                Thank you for subscribing to HYYPP.com
              </ParagraphGillC2H19>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Newsletters;
