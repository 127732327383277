import React from 'react';
import { Box } from '@mui/material';
import HeaderGillH23 from '../typography/HeaderGillH23';
import HeaderGillO43H19 from '../typography/HeaderGillO43H19';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../navbar/Logo';
import SocialButton from '../basic/SocialButton';
import Newsletters from '../Newsletters';

const FooterNavigation = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };
  const handleSocialClick = (link) => {
    window.location.href = `${link}`;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: { xs: 'space-around', lg: 'space-between' },
        flexWrap: { xs: 'wrap', lg: 'nowrap' },
        columnGap: '40px',
        rowGap: '20px',
        alignItems: 'stretch',
        marginTop: '74px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
        <Logo width="176px" height="42.34px" />

        {/* <img
          src="images/slogan.svg"
          alt="Slogan"
          style={{ width: '123.61px', height: '12.65px', marginTop: '12px' }}
        /> */}
        <svg
          style={{ width: '123.61px', height: '12.65px', marginTop: '12px' }}
          width="124"
          height="13"
          viewBox="0 0 124 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.9">
            <path
              d="M4.68952 3.23642H6.07876L1.66708 12.6493H0.291401L2.41253 8.13602L0 3.23642H1.40279L3.08343 6.75356L4.68952 3.23642Z"
              fill="white"
            />
            <path
              d="M11.607 3.22287C12.5557 3.22287 13.3441 3.53008 13.9721 4.14451C14.6 4.75442 14.914 5.52245 14.914 6.44861C14.914 7.34766 14.5955 8.09536 13.9585 8.69172C13.3215 9.28356 12.5196 9.57947 11.5528 9.57947C10.6176 9.57947 9.83598 9.27904 9.208 8.67816C8.58002 8.07277 8.26603 7.31829 8.26603 6.41472C8.26603 5.50212 8.58227 4.74312 9.21477 4.13773C9.85179 3.52782 10.6492 3.22287 11.607 3.22287ZM11.5392 4.33426C10.9474 4.33426 10.4617 4.52853 10.0822 4.91706C9.7027 5.30559 9.51295 5.8003 9.51295 6.40117C9.51295 6.99753 9.70722 7.48545 10.0958 7.86495C10.4843 8.23993 10.9835 8.42742 11.5934 8.42742C12.1988 8.42742 12.6935 8.23767 13.0775 7.85818C13.4661 7.47416 13.6603 6.98397 13.6603 6.38762C13.6603 5.79578 13.4593 5.30559 13.0572 4.91706C12.6551 4.52853 12.1491 4.33426 11.5392 4.33426Z"
              fill="white"
            />
            <path
              d="M21.9196 9.47105V8.67816C21.6575 8.96279 21.3571 9.18416 21.0183 9.34229C20.6839 9.50041 20.3496 9.57947 20.0153 9.57947C19.6223 9.57947 19.2586 9.48234 18.9242 9.28807C18.5944 9.08929 18.3437 8.82273 18.172 8.48841C18.0049 8.14958 17.9213 7.58936 17.9213 6.80778V3.23642H19.1547V6.79422C19.1547 7.44931 19.2473 7.90787 19.4325 8.16991C19.6223 8.42742 19.9521 8.55618 20.4219 8.55618C21.0092 8.55618 21.5085 8.2693 21.9196 7.69553V3.23642H23.153V9.47105H21.9196Z"
              fill="white"
            />
            <path
              d="M27.8747 3.23642V4.66632L27.9425 4.55789C28.5389 3.59559 29.1352 3.11444 29.7316 3.11444C30.1969 3.11444 30.6826 3.34937 31.1886 3.81922L30.538 4.90351C30.1088 4.4969 29.7112 4.2936 29.3453 4.2936C28.9477 4.2936 28.6021 4.48335 28.3084 4.86285C28.0193 5.24234 27.8747 5.69187 27.8747 6.21142V9.47105H26.6346V3.23642H27.8747Z"
              fill="white"
            />
            <path
              d="M39.1954 0.00390625H40.4288V3.68369C40.9619 3.29515 41.5312 3.10089 42.1366 3.10089C42.9272 3.10089 43.5732 3.39907 44.0747 3.99542C44.5762 4.59178 44.8269 5.35755 44.8269 6.29274C44.8269 7.2957 44.5265 8.09536 43.9256 8.69172C43.3293 9.28356 42.5296 9.57947 41.5267 9.57947C41.1246 9.57947 40.6999 9.53655 40.2526 9.45072C39.8099 9.36488 39.4575 9.25645 39.1954 9.12543V0.00390625ZM40.4288 4.86285V8.23767C40.849 8.36417 41.2804 8.42742 41.7232 8.42742C42.2698 8.42742 42.7148 8.23316 43.0582 7.84462C43.4061 7.45609 43.58 6.95687 43.58 6.34696C43.58 5.72801 43.4174 5.22427 43.0921 4.83574C42.7713 4.4472 42.3512 4.25294 41.8316 4.25294C41.3708 4.25294 40.9032 4.45624 40.4288 4.86285Z"
              fill="white"
            />
            <path
              d="M49.176 3.23642V4.66632L49.2438 4.55789C49.8401 3.59559 50.4365 3.11444 51.0328 3.11444C51.4982 3.11444 51.9838 3.34937 52.4898 3.81922L51.8393 4.90351C51.4101 4.4969 51.0125 4.2936 50.6465 4.2936C50.249 4.2936 49.9034 4.48335 49.6097 4.86285C49.3206 5.24234 49.176 5.69187 49.176 6.21142V9.47105H47.9358V3.23642H49.176Z"
              fill="white"
            />
            <path
              d="M58.9397 5.7235V8.37321C58.9397 8.58555 59.012 8.69172 59.1565 8.69172C59.3056 8.69172 59.5383 8.58103 59.8545 8.35966V9.11188C59.5744 9.29259 59.3485 9.41457 59.1769 9.47782C59.0097 9.54559 58.8335 9.57947 58.6483 9.57947C58.1197 9.57947 57.808 9.37165 57.7131 8.95601C57.189 9.36262 56.6311 9.56592 56.0392 9.56592C55.6055 9.56592 55.2441 9.42361 54.9549 9.13898C54.6658 8.84984 54.5212 8.48841 54.5212 8.0547C54.5212 7.66165 54.6613 7.31152 54.9414 7.0043C55.226 6.69257 55.6281 6.44635 56.1477 6.26564L57.7266 5.7235V5.39143C57.7266 4.64147 57.3517 4.26649 56.6017 4.26649C55.9285 4.26649 55.2735 4.61436 54.6364 5.31011V3.96154C55.1153 3.39681 55.8043 3.11444 56.7034 3.11444C57.3765 3.11444 57.9164 3.29064 58.323 3.64303C58.4585 3.75597 58.5805 3.90732 58.6889 4.09707C58.7974 4.2823 58.8651 4.46979 58.8922 4.65954C58.9239 4.84477 58.9397 5.19943 58.9397 5.7235ZM57.7266 8.23767V6.38762L56.8999 6.70613C56.4797 6.87329 56.1815 7.04271 56.0053 7.21438C55.8337 7.38154 55.7478 7.59162 55.7478 7.84462C55.7478 8.10214 55.8291 8.31222 55.9918 8.47486C56.159 8.6375 56.3735 8.71882 56.6356 8.71882C57.0286 8.71882 57.3923 8.55844 57.7266 8.23767Z"
              fill="white"
            />
            <path
              d="M63.8444 3.23642V4.0293C64.3956 3.4194 65.0213 3.11444 65.7216 3.11444C66.1101 3.11444 66.4716 3.21609 66.8059 3.4194C67.1402 3.61818 67.3932 3.89377 67.5649 4.24616C67.7411 4.59403 67.8292 5.14747 67.8292 5.90647V9.47105H66.5958V5.92002C66.5958 5.28301 66.4987 4.82896 66.3044 4.55789C66.1101 4.2823 65.7849 4.14451 65.3285 4.14451C64.7457 4.14451 64.251 4.43591 63.8444 5.01871V9.47105H62.584V3.23642H63.8444Z"
              fill="white"
            />
            <path
              d="M75.3769 0.00390625H76.617V9.47105H73.9808C73.0592 9.47105 72.3228 9.17964 71.7716 8.59684C71.225 8.01404 70.9516 7.23245 70.9516 6.25208C70.9516 5.33496 71.2385 4.58048 71.8123 3.98864C72.3906 3.39681 73.127 3.10089 74.0215 3.10089C74.4371 3.10089 74.8889 3.18899 75.3769 3.36518V0.00390625ZM75.3769 8.41387V4.46302C74.9928 4.26875 74.6043 4.17162 74.2113 4.17162C73.5968 4.17162 73.1066 4.37266 72.7407 4.77475C72.3793 5.17684 72.1986 5.71898 72.1986 6.40117C72.1986 7.04271 72.3567 7.53741 72.6729 7.88528C72.8627 8.0931 73.0637 8.23316 73.2761 8.30544C73.4884 8.37773 73.8702 8.41387 74.4213 8.41387H75.3769Z"
              fill="white"
            />
            <path
              d="M79.7598 11.111V10.8603C79.9631 10.666 80.1054 10.4695 80.1867 10.2707C80.2681 10.0764 80.3155 9.80988 80.329 9.47105C79.8502 9.33099 79.6107 9.03507 79.6107 8.58329C79.6107 8.33933 79.692 8.12925 79.8547 7.95305C80.0218 7.77234 80.2184 7.68198 80.4443 7.68198C80.7198 7.68198 80.9457 7.80396 81.1219 8.04792C81.2981 8.29189 81.3862 8.60362 81.3862 8.98312C81.3862 9.4846 81.2417 9.92509 80.9525 10.3046C80.6634 10.6841 80.2658 10.9529 79.7598 11.111Z"
              fill="white"
            />
            <path
              d="M94.0149 3.23642H95.3499L92.5985 9.57947H92.1851L89.366 3.23642H90.7146L92.3952 7.07207L94.0149 3.23642Z"
              fill="white"
            />
            <path
              d="M98.5875 0.613815C98.7909 0.613815 98.9648 0.683842 99.1094 0.823895C99.2539 0.963948 99.3262 1.13563 99.3262 1.33893C99.3262 1.53771 99.2539 1.70939 99.1094 1.85396C98.9648 1.99853 98.7909 2.07082 98.5875 2.07082C98.3978 2.07082 98.2306 1.99853 98.0861 1.85396C97.9415 1.70487 97.8692 1.5332 97.8692 1.33893C97.8692 1.14918 97.9415 0.982019 98.0861 0.837448C98.2306 0.688359 98.3978 0.613815 98.5875 0.613815ZM97.9776 3.23642H99.211V9.47105H97.9776V3.23642Z"
              fill="white"
            />
            <path
              d="M103.987 3.23642V4.66632L104.055 4.55789C104.651 3.59559 105.247 3.11444 105.844 3.11444C106.309 3.11444 106.795 3.34937 107.301 3.81922L106.65 4.90351C106.221 4.4969 105.824 4.2936 105.458 4.2936C105.06 4.2936 104.714 4.48335 104.421 4.86285C104.132 5.24234 103.987 5.69187 103.987 6.21142V9.47105H102.747V3.23642H103.987Z"
              fill="white"
            />
            <path
              d="M113.751 5.7235V8.37321C113.751 8.58555 113.823 8.69172 113.968 8.69172C114.117 8.69172 114.349 8.58103 114.666 8.35966V9.11188C114.385 9.29259 114.16 9.41457 113.988 9.47782C113.821 9.54559 113.645 9.57947 113.459 9.57947C112.931 9.57947 112.619 9.37165 112.524 8.95601C112 9.36262 111.442 9.56592 110.85 9.56592C110.417 9.56592 110.055 9.42361 109.766 9.13898C109.477 8.84984 109.332 8.48841 109.332 8.0547C109.332 7.66165 109.472 7.31152 109.752 7.0043C110.037 6.69257 110.439 6.44635 110.959 6.26564L112.538 5.7235V5.39143C112.538 4.64147 112.163 4.26649 111.413 4.26649C110.74 4.26649 110.084 4.61436 109.447 5.31011V3.96154C109.926 3.39681 110.615 3.11444 111.514 3.11444C112.188 3.11444 112.727 3.29064 113.134 3.64303C113.27 3.75597 113.392 3.90732 113.5 4.09707C113.608 4.2823 113.676 4.46979 113.703 4.65954C113.735 4.84477 113.751 5.19943 113.751 5.7235ZM112.538 8.23767V6.38762L111.711 6.70613C111.291 6.87329 110.993 7.04271 110.816 7.21438C110.645 7.38154 110.559 7.59162 110.559 7.84462C110.559 8.10214 110.64 8.31222 110.803 8.47486C110.97 8.6375 111.185 8.71882 111.447 8.71882C111.84 8.71882 112.203 8.55844 112.538 8.23767Z"
              fill="white"
            />
            <path
              d="M117.449 0.00390625H118.683V9.47105H117.449V0.00390625Z"
              fill="white"
            />
            <path
              d="M122.774 7.95983C123.005 7.95983 123.201 8.03889 123.364 8.19701C123.526 8.35514 123.608 8.54941 123.608 8.77982C123.608 9.00571 123.526 9.19546 123.364 9.34906C123.206 9.50267 123.009 9.57947 122.774 9.57947C122.544 9.57947 122.349 9.50267 122.191 9.34906C122.033 9.19546 121.954 9.00571 121.954 8.77982C121.954 8.54941 122.033 8.35514 122.191 8.19701C122.349 8.03889 122.544 7.95983 122.774 7.95983Z"
              fill="white"
            />
          </g>
        </svg>

        <Box sx={{ display: 'flex', gap: '12.41px', marginTop: '26.35px' }}>
          <Box
            onClick={() => {
              handleSocialClick(process.env.REACT_APP_FACEBOOK);
            }}
            sx={{ cursor: 'pointer' }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19.8481" cy="19.8481" r="19.8481" fill="#313131" />
              <path
                d="M23.5669 20.6836L24.0044 17.8086H21.2231V15.9336C21.2231 15.1211 21.5981 14.3711 22.8481 14.3711H24.1294V11.9023C24.1294 11.9023 22.9731 11.6836 21.8794 11.6836C19.5981 11.6836 18.0981 13.0898 18.0981 15.5898V17.8086H15.5356V20.6836H18.0981V27.6836H21.2231V20.6836H23.5669Z"
                fill="white"
              />
            </svg>
          </Box>
          <Box
            onClick={() => {
              handleSocialClick(process.env.REACT_APP_TWITTER);
            }}
            sx={{ cursor: 'pointer' }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19.9494" cy="19.8481" r="19.8481" fill="#313131" />
              <path
                d="M26.9133 16.4336C27.5383 15.9648 28.1008 15.4023 28.5383 14.7461C27.9758 14.9961 27.3196 15.1836 26.6633 15.2461C27.3508 14.8398 27.8508 14.2148 28.1008 13.4336C27.4758 13.8086 26.7571 14.0898 26.0383 14.2461C25.4133 13.5898 24.5696 13.2148 23.6321 13.2148C21.8196 13.2148 20.3508 14.6836 20.3508 16.4961C20.3508 16.7461 20.3821 16.9961 20.4446 17.2461C17.7258 17.0898 15.2883 15.7773 13.6633 13.8086C13.3821 14.2773 13.2258 14.8398 13.2258 15.4648C13.2258 16.5898 13.7883 17.5898 14.6946 18.1836C14.1633 18.1523 13.6321 18.0273 13.1946 17.7773V17.8086C13.1946 19.4023 14.3196 20.7148 15.8196 21.0273C15.5696 21.0898 15.2571 21.1523 14.9758 21.1523C14.7571 21.1523 14.5696 21.1211 14.3508 21.0898C14.7571 22.4023 15.9758 23.3398 17.4133 23.3711C16.2883 24.2461 14.8821 24.7773 13.3508 24.7773C13.0696 24.7773 12.8196 24.7461 12.5696 24.7148C14.0071 25.6523 15.7258 26.1836 17.6008 26.1836C23.6321 26.1836 26.9133 21.2148 26.9133 16.8711C26.9133 16.7148 26.9133 16.5898 26.9133 16.4336Z"
                fill="white"
              />
            </svg>
          </Box>

          <Box
            onClick={() => {
              handleSocialClick(process.env.REACT_APP_INSTAGRAM);
            }}
            sx={{ cursor: 'pointer' }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20.0507" cy="19.8481" r="19.8481" fill="#313131" />
              <path
                d="M20.0508 16.0898C18.0508 16.0898 16.4571 17.7148 16.4571 19.6836C16.4571 21.6836 18.0508 23.2773 20.0508 23.2773C22.0196 23.2773 23.6446 21.6836 23.6446 19.6836C23.6446 17.7148 22.0196 16.0898 20.0508 16.0898ZM20.0508 22.0273C18.7696 22.0273 17.7071 20.9961 17.7071 19.6836C17.7071 18.4023 18.7383 17.3711 20.0508 17.3711C21.3321 17.3711 22.3633 18.4023 22.3633 19.6836C22.3633 20.9961 21.3321 22.0273 20.0508 22.0273ZM24.6133 15.9648C24.6133 15.4961 24.2383 15.1211 23.7696 15.1211C23.3008 15.1211 22.9258 15.4961 22.9258 15.9648C22.9258 16.4336 23.3008 16.8086 23.7696 16.8086C24.2383 16.8086 24.6133 16.4336 24.6133 15.9648ZM26.9883 16.8086C26.9258 15.6836 26.6758 14.6836 25.8633 13.8711C25.0508 13.0586 24.0508 12.8086 22.9258 12.7461C21.7696 12.6836 18.3008 12.6836 17.1446 12.7461C16.0196 12.8086 15.0508 13.0586 14.2071 13.8711C13.3946 14.6836 13.1446 15.6836 13.0821 16.8086C13.0196 17.9648 13.0196 21.4336 13.0821 22.5898C13.1446 23.7148 13.3946 24.6836 14.2071 25.5273C15.0508 26.3398 16.0196 26.5898 17.1446 26.6523C18.3008 26.7148 21.7696 26.7148 22.9258 26.6523C24.0508 26.5898 25.0508 26.3398 25.8633 25.5273C26.6758 24.6836 26.9258 23.7148 26.9883 22.5898C27.0508 21.4336 27.0508 17.9648 26.9883 16.8086ZM25.4883 23.8086C25.2696 24.4336 24.7696 24.9023 24.1758 25.1523C23.2383 25.5273 21.0508 25.4336 20.0508 25.4336C19.0196 25.4336 16.8321 25.5273 15.9258 25.1523C15.3008 24.9023 14.8321 24.4336 14.5821 23.8086C14.2071 22.9023 14.3008 20.7148 14.3008 19.6836C14.3008 18.6836 14.2071 16.4961 14.5821 15.5586C14.8321 14.9648 15.3008 14.4961 15.9258 14.2461C16.8321 13.8711 19.0196 13.9648 20.0508 13.9648C21.0508 13.9648 23.2383 13.8711 24.1758 14.2461C24.7696 14.4648 25.2383 14.9648 25.4883 15.5586C25.8633 16.4961 25.7696 18.6836 25.7696 19.6836C25.7696 20.7148 25.8633 22.9023 25.4883 23.8086Z"
                fill="white"
              />
            </svg>
          </Box>

          <Box
            onClick={() => {
              handleSocialClick(process.env.REACT_APP_LINKEDIN);
            }}
            sx={{ cursor: 'pointer' }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20.1518" cy="19.8481" r="19.8481" fill="#313131" />
              <path
                d="M16.2769 25.6836V16.3398H13.3707V25.6836H16.2769ZM14.8082 15.0898C15.7457 15.0898 16.4957 14.3086 16.4957 13.3711C16.4957 12.4648 15.7457 11.7148 14.8082 11.7148C13.9019 11.7148 13.1519 12.4648 13.1519 13.3711C13.1519 14.3086 13.9019 15.0898 14.8082 15.0898ZM27.1207 25.6836H27.1519V20.5586C27.1519 18.0586 26.5894 16.1211 23.6519 16.1211C22.2457 16.1211 21.3082 16.9023 20.9019 17.6211H20.8707V16.3398H18.0894V25.6836H20.9957V21.0586C20.9957 19.8398 21.2144 18.6836 22.7144 18.6836C24.2144 18.6836 24.2457 20.0586 24.2457 21.1523V25.6836H27.1207Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <HeaderGillH23>Quick Links</HeaderGillH23>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            maxHeight: { xs: '100%', lg: '95px' },
            columnGap: '54px',
            marginTop: { xs: 0, lg: '16px' },
            width: '100%',
          }}
        >
          <Link to="/collabs" style={{ textDecoration: 'none' }}>
            <HeaderGillO43H19>Collabs</HeaderGillO43H19>
          </Link>

          <Link to="/influencer-marketing" style={{ textDecoration: 'none' }}>
            <HeaderGillO43H19>Influencer Marketing</HeaderGillO43H19>
          </Link>

          <Link to="/how-it-works" style={{ textDecoration: 'none' }}>
            <HeaderGillO43H19>How it works</HeaderGillO43H19>
          </Link>

          <Link to="/pricing" style={{ textDecoration: 'none' }}>
            <HeaderGillO43H19>Pricing</HeaderGillO43H19>
          </Link>

          <Link to="/faqs" style={{ textDecoration: 'none' }}>
            <HeaderGillO43H19>FAQ’s</HeaderGillO43H19>
          </Link>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <HeaderGillH23>Newsletters</HeaderGillH23>
          <Box sx={{ marginBottom: { xs: '20px', md: 0 } }}>
            <HeaderGillO43H19>
              Stay up to date on all things stellar with HYYPP.
            </HeaderGillO43H19>
          </Box>
        </Box>

        <Box sx={{ pb: '7px' }}>
          <Newsletters />
        </Box>
      </Box>
    </Box>
  );
};

export default FooterNavigation;
