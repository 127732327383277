import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGiliamsC2H33 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Giliams',
        fontSize: { xs: '28px', sm: '33px' },
        fontWeight: 400,
        lineHeight: { xs: '35.59px, sm: 40.59px' },
        color: Color2,
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGiliamsC2H33;
