import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import HowItWorksBlock1 from '../components/howItWorksComponents/HowItWorksBlock1';
import HowItWorksBlock2 from '../components/howItWorksComponents/HowItWorksBlock2';
import HowItWorksBlock3 from '../components/howItWorksComponents/HowItWorksBlock3';
import HowItWorksBlock4 from '../components/howItWorksComponents/HowItWorksBlock4';
import HowItWorksBlock5 from '../components/howItWorksComponents/HowItWorksBlock5';
import HowItWorksBlock6 from '../components/howItWorksComponents/HowItWorksBlock6';
import InfluencersSection from '../components/InfluencersSection';
import InstagramSection from '../components/InstagramSection';
import ScrollAnimation from 'react-animate-on-scroll';

const HowItWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <HowItWorksBlock1 />

      <HowItWorksBlock2 />

      <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
        <HowItWorksBlock3 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="bounceInLeft" animateOut="bounceOutRight">
        <HowItWorksBlock4 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
        <HowItWorksBlock5 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="bounceInLeft" animateOut="bounceOutRight">
        <HowItWorksBlock6 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut">
        <InfluencersSection />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <InstagramSection showTitle={true} />
      </ScrollAnimation>
    </Box>
  );
};

export default HowItWorks;
