import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Logo = ({ width, height }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
      <svg
        width="146"
        height="37"
        viewBox="0 0 146 37"
        style={{ height: height, width: width }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 35.5133C0.458477 35.5133 0.900581 35.2196 1.32631 34.6321C1.75204 34.0119 1.9649 33.4081 1.9649 32.8206V4.17942C1.9649 3.59191 1.75204 3.0044 1.32631 2.41689C0.900581 1.79674 0.458477 1.48666 0 1.48666V0.99707H9.38241V1.48666C8.92394 1.48666 8.48183 1.79674 8.0561 2.41689C7.63038 3.0044 7.41751 3.59191 7.41751 4.17942V18.3286H22.7438V4.17942C22.7438 3.59191 22.5309 3.0044 22.1052 2.41689C21.6794 1.79674 21.2373 1.48666 20.7789 1.48666V0.99707H30.1613V1.48666C29.7028 1.48666 29.2607 1.79674 28.835 2.41689C28.4092 3.0044 28.1964 3.59191 28.1964 4.17942V32.8206C28.1964 33.4081 28.4092 34.0119 28.835 34.6321C29.2607 35.2196 29.7028 35.5133 30.1613 35.5133V36.0029H20.7789V35.5133C21.2373 35.5133 21.6794 35.2196 22.1052 34.6321C22.5309 34.0119 22.7438 33.4081 22.7438 32.8206V19.4057H7.41751V32.8206C7.41751 33.4081 7.63038 34.0119 8.0561 34.6321C8.48183 35.2196 8.92394 35.5133 9.38241 35.5133V36.0029H0V35.5133Z"
          fill="url(#paint0_linear_33_17673)"
        />
        <path
          d="M35.5302 3.98358C35.1045 3.10232 34.5805 2.46585 33.9583 2.07417C33.3361 1.6825 32.7302 1.48666 32.1408 1.48666V0.99707H41.8179V1.48666C41.1957 1.48666 40.8846 1.8457 40.8846 2.56377C40.8846 2.89016 40.9501 3.23287 41.0811 3.59191L47.5652 17.4474L55.0319 3.73879C55.2611 3.31447 55.3757 2.89016 55.3757 2.46585C55.3757 2.17209 55.2939 1.94362 55.1301 1.78042C54.9991 1.58458 54.819 1.48666 54.5898 1.48666V0.99707H59.1091V1.48666C58.6178 1.48666 58.1102 1.6825 57.5863 2.07417C57.0623 2.46585 56.5547 3.06968 56.0635 3.88567L48.0565 18.5245V32.8206C48.0565 33.4081 48.2693 34.0119 48.6951 34.6321C49.1208 35.2196 49.5629 35.5133 50.0214 35.5133V36.0029H40.639V35.5133C41.0974 35.5133 41.5395 35.2196 41.9653 34.6321C42.391 34.0119 42.6039 33.4081 42.6039 32.8206V18.8182L35.5302 3.98358Z"
          fill="url(#paint1_linear_33_17673)"
        />
        <path
          d="M64.4569 3.98358C64.0312 3.10232 63.5072 2.46585 62.885 2.07417C62.2628 1.6825 61.6569 1.48666 61.0674 1.48666V0.99707H70.7446V1.48666C70.1224 1.48666 69.8112 1.8457 69.8112 2.56377C69.8112 2.89016 69.8768 3.23287 70.0077 3.59191L76.4919 17.4474L83.9586 3.73879C84.1878 3.31447 84.3024 2.89016 84.3024 2.46585C84.3024 2.17209 84.2206 1.94362 84.0568 1.78042C83.9258 1.58458 83.7457 1.48666 83.5165 1.48666V0.99707H88.0357V1.48666C87.5445 1.48666 87.0369 1.6825 86.5129 2.07417C85.989 2.46585 85.4814 3.06968 84.9901 3.88567L76.9831 18.5245V32.8206C76.9831 33.4081 77.196 34.0119 77.6217 34.6321C78.0475 35.2196 78.4896 35.5133 78.9481 35.5133V36.0029H69.5656V35.5133C70.0241 35.5133 70.4662 35.2196 70.892 34.6321C71.3177 34.0119 71.5305 33.4081 71.5305 32.8206V18.8182L64.4569 3.98358Z"
          fill="url(#paint2_linear_33_17673)"
        />
        <path
          d="M89.9941 35.5133C90.4526 35.5133 90.8947 35.2196 91.3204 34.6321C91.7462 34.0119 91.959 33.4081 91.959 32.8206V4.17942C91.959 3.59191 91.7462 3.0044 91.3204 2.41689C90.8947 1.79674 90.4526 1.48666 89.9941 1.48666V0.99707H100.605C104.24 0.99707 107.269 1.25819 109.692 1.78042C112.148 2.30265 114.081 3.29815 115.489 4.76693C116.897 6.20307 117.601 8.27567 117.601 10.9848C117.601 14.4445 116.111 16.9088 113.131 18.3776C110.151 19.8464 105.992 20.5808 100.654 20.5808H97.4116V32.8206C97.4116 33.4081 97.6245 34.0119 98.0502 34.6321C98.4759 35.2196 98.918 35.5133 99.3765 35.5133V36.0029H89.9941V35.5133ZM100.654 19.5037C104.453 19.5037 107.318 18.8509 109.25 17.5453C111.182 16.2397 112.148 14.0529 112.148 10.9848C112.148 8.53679 111.706 6.67634 110.822 5.4034C109.938 4.09782 108.677 3.21656 107.04 2.7596C105.402 2.30265 103.257 2.07417 100.605 2.07417H97.4116V19.5037H100.654Z"
          fill="url(#paint3_linear_33_17673)"
        />
        <path
          d="M118.393 35.5133C118.852 35.5133 119.294 35.2196 119.719 34.6321C120.145 34.0119 120.358 33.4081 120.358 32.8206V4.17942C120.358 3.59191 120.145 3.0044 119.719 2.41689C119.294 1.79674 118.852 1.48666 118.393 1.48666V0.99707H129.004C132.639 0.99707 135.668 1.25819 138.091 1.78042C140.547 2.30265 142.48 3.29815 143.888 4.76693C145.296 6.20307 146 8.27567 146 10.9848C146 14.4445 144.51 16.9088 141.53 18.3776C138.55 19.8464 134.391 20.5808 129.053 20.5808H125.811V32.8206C125.811 33.4081 126.023 34.0119 126.449 34.6321C126.875 35.2196 127.317 35.5133 127.776 35.5133V36.0029H118.393V35.5133ZM129.053 19.5037C132.852 19.5037 135.717 18.8509 137.649 17.5453C139.581 16.2397 140.547 14.0529 140.547 10.9848C140.547 8.53679 140.105 6.67634 139.221 5.4034C138.337 4.09782 137.076 3.21656 135.439 2.7596C133.801 2.30265 131.656 2.07417 129.004 2.07417H125.811V19.5037H129.053Z"
          fill="url(#paint4_linear_33_17673)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_33_17673"
            x1="1.68739"
            y1="39.2159"
            x2="145.085"
            y2="30.2153"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E8BD70" />
            <stop offset="1" stop-color="#ECD084" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_33_17673"
            x1="1.68739"
            y1="39.2159"
            x2="145.085"
            y2="30.2153"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E8BD70" />
            <stop offset="1" stop-color="#ECD084" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_33_17673"
            x1="1.68739"
            y1="39.2159"
            x2="145.085"
            y2="30.2153"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E8BD70" />
            <stop offset="1" stop-color="#ECD084" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_33_17673"
            x1="1.68739"
            y1="39.2159"
            x2="145.085"
            y2="30.2153"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E8BD70" />
            <stop offset="1" stop-color="#ECD084" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_33_17673"
            x1="1.68739"
            y1="39.2159"
            x2="145.085"
            y2="30.2153"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E8BD70" />
            <stop offset="1" stop-color="#ECD084" />
          </linearGradient>
        </defs>
      </svg>
      {/* 
      <img
        src= "images/hyypp-logo.svg"
        alt="HYYPP"
        style={{ height: height, width: width }}
      /> */}
    </Box>
  );
};

export default Logo;
