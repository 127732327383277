import React from 'react';
import { Typography } from '@mui/material';

const HeaderGiliamsH81 = ({ children, textAlign }) => {
  return (
    <Typography
      component="h1"
      sx={{
        fontFamily: 'Giliams',
        fontSize: {
          xs: '38px',
          sm: '57px',
          lg: '71px',
          xl: '81px',
        },
        // fontSize: '81px',
        fontWeight: 400,
        lineHeight: {
          xs: '49.1px',
          sm: '59.1px',

          lg: '79.1px',
          xl: '89.1px',
        },
        // lineHeight: '89.1px',
        color: '#FFF',
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGiliamsH81;
