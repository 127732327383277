import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomizedButton = styled(Button)({
  borderRadius: '82px',
  padding: '15px 28px',
  background: 'linear-gradient(88.84deg, #E8BD70 2.24%, #ECD084 97%);',
  color: '#222',
  textTransform: 'none',
  fontFamily: '"Gill Sans", sans-serif',
  fontWeight: 600,
  fontSize: '19px',
  lineHeight: '24px',
  fontStyle: 'normal',
});

export default function RoundedButton({ children, link }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${link}`);
  };

  return (
    <CustomizedButton>
      <Box onClick={handleClick}>{children}</Box>
    </CustomizedButton>
  );
}
