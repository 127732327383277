import React from 'react';

import Wrapper from '../basic/Wrapper';
import { Box } from '@mui/material';
import Logo from './Logo';
import Navigation from './Navigation';
import GetStarted from './GetStarted';
import MobileMenu from './MobileMenu';

const NavBar = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: { xs: '100%', lg: '1144px' },
          paddingX: { xs: '20px', lg: 0 },
          marginX: 'auto',
          marginTop: { xs: '32px', lg: '64px' },
        }}
      >
        <Logo width="146px" height="35.01px" />

        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <Navigation />
        </Box>

        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <GetStarted />
        </Box>

        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <MobileMenu />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default NavBar;
