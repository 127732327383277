import React, { useEffect } from 'react';
import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import validateString from '../../utils/validateString';
import axios from 'axios';
import validateEmail from '../../utils/validateEmail';
import validateNumber from '../../utils/validateNumber';
import { useSnackbar } from 'notistack';
import {
  emptyEmailAddress,
  emptyFirstName,
  emptyInstagramUrl,
  emptyFacebookUrl,
  emptyMobileNumber,
  invalidEmailAddress,
  invalidMobileNumber,
  mustAccept,
  SubmitSuccess,
  validBirthOfDate,
  emptyWebsiteUrl,
  SubmitError,
  emptyBirthdate,
  emptyCountryName,
} from '../../utils/messages';
import { validateBirthOfDate } from '../../utils/validateBirthOfDate';

const CustomizedButton = styled(Button)({
  borderRadius: '82px',
  padding: '15px 28px',
  background: 'linear-gradient(88.84deg, #E8BD70 2.24%, #ECD084 97%);',
  color: '#222',
  textTransform: 'none',
  fontFamily: 'GillSansRegular',
  fontWeight: 600,
  fontSize: '19px',
  lineHeight: '24px',
  fontStyle: 'normal',
});

export default function RoundedButtonSignIn({
  children,
  onClickFun,
  submitSData,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (obj) => {
    enqueueSnackbar(obj.message, {
      variant: obj.variant,
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const type = useSelector((state) => state.signIn.type);
  const firstName = useSelector((state) => state.signIn.firstName);
  const emailAddress = useSelector((state) => state.signIn.emailAddress);
  const websiteUrlData = useSelector((state) => state.signIn.websiteUrl);
  const mobileNumber = useSelector((state) => state.signIn.mobileNumber);
  const instagramUrl = useSelector((state) => state.signIn.instagramUrl);
  const agreeTerms = useSelector((state) => state.signIn.agreeTerms);
  const birthOfDate = useSelector((state) => state.signIn.birthOfDate);
  const facebookUrl = useSelector((state) => state.signIn.facebookUrl);
  const handleClick = (event) => {
    const getItem = (key) => {
      const data =
        typeof window !== 'undefined' ? localStorage.getItem(key) : '';
      try {
        return JSON.parse(data);
      } catch (err) {
        return data;
      }
    };
    if (event && event) {
      const isValidFirstName = validateString(firstName);
      const isValidEmailAddress = validateEmail(emailAddress);
      const isValidMobileNumber = validateNumber(mobileNumber);
      // const isValidBirthOfDate = validateBirthOfDate(event.birth_date);
      // if (getItem('type') !== 'Brand') {
      //   if (event.birth_date == '') {
      //     handleAlert(emptyBirthdate);
      //   }
      // }

      if (event.country == '') {
        handleAlert(emptyCountryName);
      }

      if (!isValidFirstName) {
        handleAlert(emptyFirstName);
      }

      if (emailAddress.length < 1) {
        handleAlert(emptyEmailAddress);
      } else if (!isValidEmailAddress) {
        handleAlert(invalidEmailAddress);
      }

      if (mobileNumber.length < 1) {
        handleAlert(emptyMobileNumber);
      } else if (!isValidMobileNumber) {
        handleAlert(invalidMobileNumber);
      }

      if (instagramUrl) {
        handleAlert(emptyInstagramUrl);
      }
      if (facebookUrl) {
        handleAlert(emptyFacebookUrl);
      }

      if (websiteUrlData) {
        handleAlert(emptyWebsiteUrl);
      }

      if (!agreeTerms) {
        handleAlert(mustAccept);
      }

      if (
        isValidFirstName &&
        isValidEmailAddress &&
        isValidMobileNumber &&
        !instagramUrl &&
        event.country !== '' &&
        !facebookUrl &&
        !websiteUrlData &&
        agreeTerms
      ) {
        if (getItem('type') == 'Brand') {
          delete event.birth_date;
          delete event.gender;
          event.role = 'brand';
        } else {
          delete event.brand_name;
          delete event.franchise;
          event.role = 'influencer';
        }

        if (event.role !== 'brand') {
          if (event.birth_date == '') {
            handleAlert(emptyBirthdate);
          }
          const isValidBirthOfDate = validateBirthOfDate(event.birth_date);
          if (isValidBirthOfDate) {
            handleAlert(validBirthOfDate);
          }
          if (!isValidBirthOfDate && event.birth_date !== '') {
            event.mobile_number = `${
              event.mobile_number !== ''
                ? `${event.mobile_Code} ${event.mobile_number}`
                : ''
            }`;
            delete event.mobile_Code;
            event.whatsapp_number = `${
              event.whatsapp_number !== ''
                ? `${event.whatsapp_Code} ${event.whatsapp_number}`
                : ''
            }`;
            delete event.whatsapp_Code;
            delete event.whatsapp_Code_Name;
            console.log('eventa for influncer', event);
            axios
              .post('https://api.hyypp.com/inquiries', event)
              .then(function (response) {
                if (response?.data?.error == false) {
                  handleAlert(SubmitSuccess);
                  localStorage.removeItem('type');
                  navigate('/');
                } else {
                  handleAlert(SubmitError);
                  navigate('/');
                }
              })
              .catch(function (error) {
                handleAlert({
                  message: error.response.data.message,
                  variant: 'error',
                });
              });
          }
        } else {
          event.mobile_number = `${
            event.mobile_number !== ''
              ? `${event.mobile_Code} ${event.mobile_number}`
              : ''
          }`;
          delete event.mobile_Code;
          event.whatsapp_number = `${
            event.whatsapp_number !== ''
              ? `${event.whatsapp_Code} ${event.whatsapp_number}`
              : ''
          }`;
          delete event.whatsapp_Code;
          delete event.whatsapp_Code_Name;
          console.log('eventa for brand', event);
          axios
            .post('https://api.hyypp.com/inquiries', event)
            .then(function (response) {
              if (response?.data?.error == false) {
                handleAlert(SubmitSuccess);
                localStorage.removeItem('type');
                navigate('/');
              } else {
                handleAlert(SubmitError);
                navigate('/');
              }
            })
            .catch(function (error) {
              handleAlert({
                message: error.response.data.message,
                variant: 'error',
              });
            });
        }
      }
    }
  };
  useEffect(() => {
    handleClick(submitSData);
  }, [submitSData]);
  return (
    <CustomizedButton
      onClick={() => {
        onClickFun();
        handleClick();
      }}
    >
      <Box>{children}</Box>
    </CustomizedButton>
  );
}
