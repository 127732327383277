import React from 'react';
import { Box } from '@mui/material';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';
import Wrapper from '../basic/Wrapper';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import { Color4 } from '../../colors/Colors';
import HeaderGiliamsH40 from '../typography/HeaderGiliamsH40';

const Block2 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          background:
            '#222222 url("images/iphone-background.png") no-repeat center',
          paddingBottom: '190px',
          backgroundPosition: { xs: '0 -44px', md: '0 130px' },
        }}
      >
        <Box
          sx={{
            backgroundImage: `radial-gradient(rgba(34,34,34,0.94) 10%, ${Color4} 72%)`,
            paddingBottom: '260px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: { xs: '50px', lg: '65px' },
            }}
          >
            <Box sx={{ marginBottom: '22px' }}>
              <HeaderGiliamsH71 textAlign="center">
                Hey there good lookin’
              </HeaderGiliamsH71>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                gap: '60px',
                marginX: 'auto',
                marginBottom: '19px',
                paddingX: { xs: '20px', lg: 0 },
              }}
            >
              <Box sx={{ maxWidth: '519px' }}>
                <ParagraphGillC2H19>
                  We are HYYPP, a dynamic influencer platform revolutionizing
                  the way brands connect with local influencers. Whether you're
                  a small business looking to increase brand awareness or a
                  multinational corporation seeking to expand your reach, HYYPP
                  has got your creator needs covered, by harnessing cutting edge
                  technology.
                </ParagraphGillC2H19>
              </Box>

              <Box sx={{ maxWidth: '524px' }}>
                <ParagraphGillC2H19>
                  Our platform harnesses the power and reach of micro and macro
                  influencers, connecting brands with a network of highly
                  engaged and authentic local and global influencers who will
                  help you instantly promote your brand to a relevant and real
                  targeted audience. It’s simple, fast and extremely cost
                  effective to go viral.
                </ParagraphGillC2H19>
              </Box>
            </Box>

            <Box
              sx={{
                marginX: 'auto',
                marginTop: '68px',
                paddingX: { xs: '20px', lg: 0 },
              }}
            >
              <HeaderGiliamsH40 textAlign={{ xs: 'center', lg: 'left' }}>
                Influencer
                <span style={{ color: '#EAC67A' }}> collaborations </span>
                have never been
                <span style={{ color: '#EAC67A' }}> simpler.</span>
              </HeaderGiliamsH40>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginX: 'auto',
                marginTop: '68px',
                maxWidth: { xs: '335px', sm: '550px', md: '605.51px' },
                paddingX: { xs: '20px', lg: 0 },
              }}
            >
              <img
                src="images/block2-iphone.png"
                alt="iPhone"
                style={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Block2;
