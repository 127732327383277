import React from 'react';
import { Avatar, Box } from '@mui/material';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import ParagraphGillC2O80H15 from '../typography/ParagraphGillC2O80H15';

const InfluencerSmallCard = ({ avatar, name, user }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '32px',
        marginLeft: '32px',
      }}
    >
      <Box sx={{ marginRight: '14px' }}>
        <Avatar
          alt={name}
          src={`images/influencers/${avatar}`}
          sx={{ width: '53.58px', height: '53.58px' }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ marginBottom: '-1px' }}>
          <ParagraphGillC2H19>{name}</ParagraphGillC2H19>
        </Box>

        <Box sx={{ marginTop: '-1px' }}>
          <ParagraphGillC2O80H15>{user}</ParagraphGillC2O80H15>
        </Box>
      </Box>
    </Box>
  );
};

export default InfluencerSmallCard;
