import React from 'react';
import { Box, Button } from '@mui/material';
import HeaderGillC4H19 from '../typography/HeaderGillC4H19';

const storeButton = ({ icon, title, link }) => {
  const handleClick = () => {
    window.location.href = `${link}`;
  };

  return (
    <Button
      sx={{
        width: '220px',
        height: '70px',
        borderRadius: '60px',
        backgroundColor: '#FFF',
      }}
      variant="contained"
      startIcon={<img src={icon} alt={title} />}
      onClick={handleClick}
    >
      <Box sx={{ maxWidth: '110px', margin: 0, padding: 0 }}>
        <HeaderGillC4H19>{title}</HeaderGillC4H19>
      </Box>
    </Button>
  );
};

export default storeButton;
