import moment from 'moment';

export const validateBirthOfDate = (str) => {
  return moment().diff(moment(str).format('YYYY'), 'years') < 13;
};

export const validateFaceBook = (str) => {
  // console.log('strstrstrstrstrstr',str);
  let regFacebook = /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/;
  // console.log('regFacebook.test(str)',regFacebook.test(str));
  if (!regFacebook.test(str)) {
    // setFacebookValidate(true);
    regFacebook = false;
  } else {
    // setFacebookValidate(false);
    regFacebook = true;
  }

  return regFacebook;
  // return moment().diff(moment(str).format('YYYY'), 'years') < 13;
};

export const validateInstagram = (str) => {
  console.log('ksdmesk', str);
  let regInstagram = /^(https?:\/\/){0,1}(www\.){0,1}instagram\.com/;
  console.log('regInstagram.test(str)', regInstagram.test(str));
  if (!regInstagram.test(str)) {
    regInstagram = false;
  } else {
    regInstagram = true;
  }
  return regInstagram;
};
