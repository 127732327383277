import React from 'react';
import { Typography } from '@mui/material';

const HeaderGiliamsH29 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Giliams',
        fontSize: { xs: '22px', sm: '24px', md: '29px' },
        // fontSize: '29px',
        fontWeight: 400,
        lineHeight: { xs: '26.9px', md: '31.9px' },
        // lineHeight: '31.9px',
        color: '#EAC77A',
        textAlign: textAlign ? textAlign : 'left',
        paddingX: { xs: '20px', sm: 0 },
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGiliamsH29;
