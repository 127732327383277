import React from 'react';
import { Box } from '@mui/material';
import HeaderGillC1H16 from '../typography/HeaderGillC1H16';

const ScrollDown = () => {
  const handleClick = () => {
    window.scroll({ top: 1000, left: 0, behavior: 'smooth' });
  };

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginBottom: '4px' }}
      >
        <img src="images/mouse-scroll-down.svg" alt="Scroll Down" />
      </Box>

      <HeaderGillC1H16 textAlign="center">Scroll Down</HeaderGillC1H16>
    </Box>
  );
};

export default ScrollDown;
