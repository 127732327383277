import React from 'react';
import { Box } from '@mui/material';
import Wrapper from '../basic/Wrapper';
import HeaderGiliamsH43 from '../typography/HeaderGiliamsH43';
import { Color6 } from '../../colors/Colors';
import HeaderGillC7H22 from '../typography/HeaderGillC7H22';
import Circle from '../basic/Circle';
import HeaderGillC1H22 from '../typography/HeaderGillC1H22';
import Chip from '../basic/Chip';
import block2Influencers from './collabs/block2-influencers.png';
import HeaderGillH20 from '../typography/HeaderGillH20';

const CollabsBlock2 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: 'center',
          maxWidth: '1142px',
          marginX: 'auto',
          marginTop: '108px',
          columnGap: '20px',
          rowGap: '40px',
          flexWrap: { xs: 'wrap', md: 'nowrap' },
          paddingX: { xs: '20px', lg: 0 },
        }}
      >
        <Box
          sx={{ maxWidth: { xs: '100%', lg: '408px' }, position: 'relative' }}
        >
          <img
            src={block2Influencers}
            alt="Influencers"
            style={{ width: '100%', height: '100%' }}
          />
          <div style={{ width: '100%' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '54px',
                bottom: '',
                left: '',
                right: '0px',
                backgroundColor: Color6,
                padding: '16px 24px 10px 23px',
                borderRadius: '70px',
                display: { xs: 'flex', sm: 'flex' },
                marginLeft: '43px',
              }}
            >
              <Box sx={{ width: '30px', overflow: 'hidden', marginTop: '3px' }}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4" clip-path="url(#clip0_505_265)">
                    <path
                      d="M12 8C9.79085 8 8 9.79085 8 12C8 14.2092 9.79085 16 12 16C14.2092 16 16 14.2092 16 12C16 9.79085 14.2092 8 12 8Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 23.25C9.6389 23.25 7.72765 23.1934 6.22855 23.1179C3.2835 22.9696 1.03039 20.7165 0.882085 17.7715C0.806595 16.2724 0.75 14.3611 0.75 12C0.75 9.6389 0.8066 7.72765 0.882085 6.22855C1.03039 3.28349 3.2835 1.03039 6.22855 0.882085C7.72765 0.806595 9.6389 0.75 12 0.75C14.3611 0.75 16.2724 0.806595 17.7715 0.882085C20.7165 1.03039 22.9696 3.2835 23.1179 6.22855C23.1934 7.72765 23.25 9.6389 23.25 12C23.25 14.3611 23.1934 16.2723 23.1179 17.7715C22.9696 20.7165 20.7165 22.9696 17.7715 23.1179C16.2724 23.1934 14.3611 23.25 12 23.25ZM6 12C6 8.6863 8.6863 6 12 6C15.3137 6 18 8.6863 18 12C18 15.3137 15.3137 18 12 18C8.6863 18 6 15.3137 6 12ZM18.5 4C17.9477 4 17.5 4.44771 17.5 5C17.5 5.5523 17.9477 6 18.5 6H19C19.5523 6 20 5.5523 20 5C20 4.44771 19.5523 4 19 4H18.5Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_505_265">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>

              <Box sx={{ marginLeft: '4px', marginBottom: '1px' }}>
                <HeaderGillH20>500K Followers</HeaderGillH20>
              </Box>
            </Box>
            {/* <Chip
              image="collabs-block2-followers1"
              alt="500K Followers"
              top="54px"
              right="0px"
              mt="3px"
            /> */}
          </div>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '26px' }}>
          <Box>
            <HeaderGiliamsH43>For Influencers</HeaderGiliamsH43>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              maxWidth: '516px',
            }}
          >
            <Box>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>01</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box>
              <HeaderGillC1H22>
                Add value and professionalism to your
              </HeaderGillC1H22>
              <HeaderGillC1H22>collaborations</HeaderGillC1H22>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              maxWidth: '516px',
            }}
          >
            <Box>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>02</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box>
              <HeaderGillC1H22>
                Provide insightful marketing metrics that
              </HeaderGillC1H22>
              <HeaderGillC1H22>
                can help a brand select your services
              </HeaderGillC1H22>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              maxWidth: '516px',
            }}
          >
            <Box>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>03</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box>
              <HeaderGillC1H22>
                Build trust through transparent campaign
              </HeaderGillC1H22>
              <HeaderGillC1H22>
                metrics and engagement results sharing
              </HeaderGillC1H22>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              maxWidth: '516px',
            }}
          >
            <Box>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>04</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box>
              <HeaderGillC1H22>
                Keeps brands in the loop with campaign or
              </HeaderGillC1H22>
              <HeaderGillC1H22>collaboration data performance.</HeaderGillC1H22>
            </Box>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default CollabsBlock2;
