import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import Collabs from './pages/Collabs';
import Influencers from './pages/Influencers';
import HowItWorks from './pages/HowItWorks';
import Pricing from './pages/Pricing';
import GetStarted from './pages/GetStarted';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import FAQs from './pages/FAQs';
import { createTheme, ThemeProvider } from '@mui/material';
import SignIn from './pages/SignIn';
import { SnackbarProvider } from 'notistack';

// Before build, if website not in the root:
// <BrowserRouter basename="/hyypp/">

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1400,
    },
  },
});
const App = () => (
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={6}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="/collabs" element={<Collabs />} />
            <Route path="/influencer-marketing" element={<Influencers />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/get-started" element={<GetStarted />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  </ThemeProvider>
);

export default App;
