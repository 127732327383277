import React from 'react';

import RoundedButton from '../basic/RoundedButton';

const GetStarted = () => {
  return (
    <RoundedButton link={process.env.REACT_APP_GET_STARTED}>
      Get Started
    </RoundedButton>
  );
};

export default GetStarted;
