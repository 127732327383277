import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Wrapper from '../components/basic/Wrapper';
import HeaderGiliamsH71 from '../components/typography/HeaderGiliamsH71';
import ParagraphGillC2H19 from '../components/typography/ParagraphGillC2H19';
import HeaderGiliamsC2H33 from '../components/typography/HeaderGiliamsC2H33';
import { Color2 } from '../colors/Colors';

const style = {
  fontFamily: '"Gill Sans", sans-serif',
  fontSize: '19px',
  fontWeight: 400,
  lineHeight: '30.97px',
  color: Color2,
};

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '54px',
          width: { xs: '100%', lg: '1142px' },
          marginTop: { xs: '30px', sm: '74px' },
          marginX: 'auto',
          paddingX: { xs: '20px', lg: 0 },
        }}
      >
        <Box
          sx={{
            backgroundImage:
              'linear-gradient(180deg, #121212 0%, #222222 100%)',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '456px',
            width: '100%',
            zIndex: '-1',
          }}
        />
        <Box>
          <HeaderGiliamsH71 textAlign="center">Privacy Policy</HeaderGiliamsH71>
        </Box>
        <Box sx={{ maxWidth: '1140px', marginX: 'auto', marginTop: '-33px' }}>
          <ParagraphGillC2H19>
            Thank you for choosing to be part of our community at Hyypp Inc.,
            operating on “Platforms” as HYYPP. We are committed to protecting
            your personal information and your right to privacy. If you have any
            questions or concerns about our policy, or our practices with
            regards to your personal information, please contact us at
            support@hyypp.com.
          </ParagraphGillC2H19>
          <ParagraphGillC2H19>
            When you visit our website add website, Mobile Application and use
            our services, you trust us with your personal information. We take
            your privacy very seriously. In this privacy policy, we seek to
            explain to you in the clearest way possible what information we
            collect, how we use it and what rights you have in relation to it.
            We hope you take some time to read through it carefully, as it is
            important. If there are ay terms in this privacy policy that you do
            not agree with, please discontinue use of our Sites or Apps and our
            services.
          </ParagraphGillC2H19>
        </Box>

        <Box sx={{ marginTop: '10px' }}>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>
              1. What Information do we collect?
            </HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              We collect personal information that you provide to us such as
              name, email, contact information, social data, security data,
              location and payment information. We collect personal information
              that you voluntarily provide to us when registering at the
              Services or “Platform”, expressing an interest in obtaining
              information about us or our products and services, when
              participating in activities on the Services or “Platform” (such as
              posting messages on our “Platform”, accepting promotions) or
              otherwise contacting us. The personal information that we collect
              depends on the context of your interactions with us and the
              Services or “Platform”, the choices you make and the products and
              feature you use. The personal information we collect can include
              the following: Credentials – We collect login confirmation, email
              used to validate and send login link, security information used
              for authentication and account access.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>
              2. How do we use your information?
            </HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              We process your information for purposes based on legitimate
              business interests, the fulfillment of our contract with you,
              compliance with our legal obligations, and/or your consent. We use
              personal information collected via our “Platform” for a variety of
              business purposes described below.
            </ParagraphGillC2H19>
            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                We process your personal information for these purposes in
                reliance on our legitimate business interests, in order to enter
                into or perform a contract with you, with your consent, and/or
                for compliance with our legal obligations. We indicate the
                specific processing grounds we rely on next to each purpose
                listed below.
              </ParagraphGillC2H19>
            </Box>
          </Box>
          <Box>
            <ol style={style}>
              <li>
                <ParagraphGillC2H19>
                  To facilitate account creation and logon process. If you
                  choose to link your account with us to a third party account
                  (such as Instagram, Google, Facebook etc). We use information
                  that you provide and have allowed for us to use for account
                  creation and the logon process for the performance of the
                  contract.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  To send you marketing and promotional communications. We
                  and/or our third party marketing partners may use the personal
                  information you send to us for our marketing purposes, if this
                  is in accordance with your marketing preferences. You can opt
                  out of our marketing emails at any time.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  To send administrative information to you. We may use your
                  personal information to send you product, service and new
                  feature information and/or information about changes to our
                  terms, conditions, and policies.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  Deliver targeted advertising to you. We may use your
                  information to develop and display content and advertising
                  (and work with third parties to do so) tailored to you
                  interests and/or location and to measure its effectiveness.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  Administer prize draws and competitions. We may use your
                  information to administer prize draws and competitions when
                  you elect to participate in competitions.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  Request Feedback. We may use your information to request
                  feedback and to contact you about your use of our “Platform”.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  To protect our “Platform”. We may use your information as part
                  of our efforts to keep our Services or Apps safe and secure
                  (for example, for fraud monitoring and prevention)
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  To enable user-to-user communications. We may use your
                  information in order to enable user –to-user communications
                  with each user’s consent.
                </ParagraphGillC2H19>
              </li>
            </ol>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>
              3. Will your information be shared with anyone?
            </HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              We only share information with your consent, to comply with laws,
              to provide you with “Services”, to protect your rights, or to
              fulfill business obligations. We may process or share data based
              on the following legal basis.
            </ParagraphGillC2H19>

            <ol style={style}>
              <li>
                <ParagraphGillC2H19>
                  Consent: we may process your data if you have given us
                  specific consent to use your personal information in a
                  specific purpose.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  Legitimate Interests: We may process your data when it is
                  reasonably necessary to achieve our legitimate business
                  interests.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  Performance of a Contract: Where we have entered into a
                  contract with you, we may process your personal information to
                  fulfill the terms of our contract.
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  Legal Obligations: We may disclose your information where we
                  are legally required to do so in order to comply with
                  applicable law, governmental requests, a judicial proceeding,
                  court order, or legal process, such as in response to a court
                  order or a subpoena (including in response to public
                  authorities to meet national security or law enforcement
                  requirements).
                </ParagraphGillC2H19>
              </li>
              <li>
                <ParagraphGillC2H19>
                  Vital interests: We may disclose your information where we
                  believe it is necessary to investigate, prevent, or take
                  action regarding potential violations of our policies,
                  suspected fraud, situations involving poten
                </ParagraphGillC2H19>
              </li>
            </ol>

            <ParagraphGillC2H19>
              We only share information with your consent, to comply with laws,
              to provide you with “Services”, to protect your rights, or to
              fulfill business obligations. We may process or share data based
              on the following legal basis.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>
              4. Do we use cookies and other tracking technologies?
            </HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              We may use cookies and other tracking technologies to collect and
              store your information.
            </ParagraphGillC2H19>
            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Policy.
              </ParagraphGillC2H19>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>
              5. How long do we keep your information?
            </HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              In Short: We keep our information for as long as necessary to
              fulfill the purposes outlined in this privacy policy unless
              otherwise required by law.
            </ParagraphGillC2H19>
            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in the privacy policy, unless
                a longer retention period is required or permitted by law (such
                as tax, accounting or other legal requirements). No purpose in
                this policy will require us keeping your personal information
                for longer than 2 years past the termination of the users
                account.
              </ParagraphGillC2H19>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize it, or
                if this is not possible (for example, because your personal
                information has been stored in backup archives). Then we will
                securely store your personal information and isolate it from any
                further processing until deletion is possible.
              </ParagraphGillC2H19>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>
              6. How do we keep your information safe?
            </HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures. We have
              implement appropriate technical and organizational security
              measures designed to protect the security of any personal
              information we process.
            </ParagraphGillC2H19>
            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                However, please also remember that we cannot guarantee that the
                internet itself is 100% secure. Although we will do our best to
                protect your personal information, transmission of personal
                information to and from our Services and “Platform” is at your
                own risk. You should only access the services within a secure
                environment.
              </ParagraphGillC2H19>
            </Box>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default PrivacyPolicy;
