import React from 'react';
import { Box } from '@mui/material';
import HeaderGillC4H22 from '../typography/HeaderGillC4H22';
import ParagraphGillH14 from '../typography/ParagraphGillH14';
import { Color2 } from '../../colors/Colors';
import Arrow from '../../assets/arrow.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signInActions } from '../../store/signIn';

const RoundedButtonBig = ({ title, description }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setType = () => {
    localStorage.setItem('type', title);
    dispatch(signInActions.setType(title));
    dispatch(signInActions.setFirstName(''));
    dispatch(signInActions.setEmailAddress(''));
    dispatch(signInActions.setMobileNumber(''));
    dispatch(signInActions.setInstagramUrl(''));
    dispatch(signInActions.setBirthOfDate(''));
    dispatch(signInActions.setFacebookUrl(''));
    dispatch(signInActions.setWebsiteUrl(''));
    dispatch(signInActions.setAgreeTerms(false));
  };

  const clickHandler = () => {
    setType();
    navigate(`../sign-in`);
  };

  return (
    <Box
      onClick={clickHandler}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '88px',
        background: 'linear-gradient(88.84deg, #E8BD70 2.24%, #ECD084 97%)',
        borderRadius: '15px',
        paddingY: '9px',
        paddingRight: '9px',
        paddingLeft: '28px',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ paddingTop: '12px' }}>
          <HeaderGillC4H22>{title}</HeaderGillC4H22>
        </Box>

        <Box sx={{ paddingBottom: '7px' }}>
          <ParagraphGillH14>{description}</ParagraphGillH14>
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            width: '73px',
            height: '100%',
            background: `url(${Arrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor: `${Color2}`,
            borderRadius: '15px',
          }}
        />
      </Box>
    </Box>
  );
};

export default RoundedButtonBig;
