import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGillC2H11 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '147%',
        opacity: '80%',
        color: Color2,
        textDecoration: 'none',
        textTransform: 'none',
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGillC2H11;
