import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const ParagraphGillC2O80H15 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '24.45px',
        color: Color2,
        opacity: '0.8',
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default ParagraphGillC2O80H15;
