import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import InfluencersBlock1 from '../components/influencersComponents/InfluencersBlock1';
import InfluencersBlock2 from '../components/influencersComponents/InfluencersBlock2';
import InfluencersBlock3 from '../components/influencersComponents/InfluencersBlock3';
import InfluencersBlock4 from '../components/influencersComponents/InfluencersBlock4';
import CampaignsSection from '../components/CampaignsSection';
import InfluencersSection from '../components/InfluencersSection';
import InstagramSection from '../components/InstagramSection';
import ScrollAnimation from 'react-animate-on-scroll';

const Influencers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <InfluencersBlock1 />

      <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutRight">
        <InfluencersBlock2 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="flipInX" animateOut="flipOutX">
        <InfluencersBlock3 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="flipInX" animateOut="flipOutX">
        <InfluencersBlock4 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="flipInX" animateOut="flipOutX">
        <CampaignsSection />
      </ScrollAnimation>

      <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut">
        <InfluencersSection />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <InstagramSection showTitle={true} />
      </ScrollAnimation>
    </Box>
  );
};

export default Influencers;
