import React from 'react';
import Wrapper from '../basic/Wrapper';
import { Box } from '@mui/material';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import Chip from '../basic/Chip';

const InfluencersBlock3 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: { xs: '100%', lg: '1175px' },
          marginX: 'auto',
          marginTop: { xs: '100px', md: '140px' },
          paddingX: { xs: '20px', lg: 0 },
          rowGap: '20px',
          columnGap: '40px',
          flexDirection: { xs: 'column-reverse', md: 'row' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: { xs: '319px', sm: '544px' },
            marginLeft: { xs: '54px', sm: 0 },
          }}
        >
          <img
            src="images/influencers/block3-hard-work.svg"
            alt="Top Countries"
            style={{
              width: '319px',
              height: '386px',
              marginRight: '140px',
              marginLeft: '86px',
            }}
          />

          <Chip
            image="block3-free-offer"
            alt="Free Offer"
            top="81px"
            left="0px"
          />

          <Chip
            image="block3-discount-offer"
            alt="Discount Offer"
            top="174px"
            left="0px"
          />

          <Chip
            image="block3-commission-based"
            alt="Commission Based"
            top="267px"
            left="0px"
          />

          <Chip
            image="block3-models-book"
            alt="Model Bookings"
            top="47px"
            right="0px"
          />

          <Chip
            image="block3-paid-post"
            alt="Paid Post"
            top="150px"
            right="16px"
          />
        </Box>

        <Box>
          <Box sx={{ maxWidth: { xs: '100%', lg: '398px' } }}>
            <HeaderGiliamsC2H33>
              Let influencers do the hard work for you
            </HeaderGiliamsC2H33>
          </Box>

          <Box
            sx={{
              maxWidth: { xs: '100%', lg: '486px' },
              marginTop: { xs: '20px', lg: '6px' },
              marginBottom: { xs: '20px', lg: 0 },
            }}
          >
            <ParagraphGillC2H19>
              Each week, influencers invest hours in making sure their content
              is impactful. They serve as an extension of your team, promoting
              your business by sharing engaging posts with their followers.
            </ParagraphGillC2H19>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default InfluencersBlock3;
