import { createSlice } from '@reduxjs/toolkit';

const initialSignIn = {
  type: '',
  firstName: '',
  emailAddress: '',
  mobileNumber: '',
  instagramUrl: '',
  birthOfDate: '',
  agreeTerms: false,
};

const signInSlice = createSlice({
  name: 'signIn',
  initialState: initialSignIn,
  reducers: {
    setType(state, action) {
      state.type = action.payload;
    },

    setFirstName(state, action) {
      state.firstName = action.payload;
    },

    setEmailAddress(state, action) {
      state.emailAddress = action.payload;
    },

    setMobileNumber(state, action) {
      state.mobileNumber = action.payload;
    },

    setInstagramUrl(state, action) {
      state.instagramUrl = action.payload;
    },

    setBirthOfDate(state, action) {
      state.birthOfDate = action.payload;
    },

    setFacebookUrl(state, action) {
      state.facebookUrl = action.payload;
    },

    setAgreeTerms(state, action) {
      state.agreeTerms = action.payload;
    },

    setWebsiteUrl(state, action) {
      state.websiteUrl = action.payload;
    },
  },
});

export const signInActions = signInSlice.actions;

export default signInSlice.reducer;
