import React from 'react';
import { Box } from '@mui/material';
import Wrapper from '../basic/Wrapper';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import HeaderGiliamsH29 from '../typography/HeaderGiliamsH29';

const HowItWorksBlock1 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          backgroundImage: 'linear-gradient(180deg, #121212 0%, #222222 100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '456px',
          width: '100%',
          zIndex: '-1',
        }}
      />
      <Box sx={{ paddingX: { xs: '20px', lg: 0 } }}>
        <Box sx={{ marginTop: { xs: '40px', sm: '74px' } }}>
          <HeaderGiliamsH71 textAlign="center">
            How Hyypp works?
          </HeaderGiliamsH71>
        </Box>

        <Box sx={{ maxWidth: '1080px', marginX: 'auto', marginTop: '20px' }}>
          <ParagraphGillC2H19 textAlign="center">
            Each promotion you create reaches all local verified
            micro-influencers. In a Promotion, you usually offer food or
            products in exchange for content. Interested influencers apply and
            then you select who you want to work with. Chat with selected
            influencers within the HYYPP App (iOS and Android) and coordinate
            when to come in, or where to deliver your products. Once food is
            redeemed or product is received, the influencer creates content and
            that's it!
          </ParagraphGillC2H19>
        </Box>

        <Box sx={{ marginX: 'auto', marginTop: '25px' }}>
          <HeaderGiliamsH29 textAlign="center">
            Your brand goes viral, quickly and cost effectively
          </HeaderGiliamsH29>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default HowItWorksBlock1;
