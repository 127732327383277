import React from 'react';
import Wrapper from '../basic/Wrapper';
import { Box } from '@mui/material';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import Circle from '../basic/Circle';
import { Color6 } from '../../colors/Colors';
import HeaderGillC7H22 from '../typography/HeaderGillC7H22';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';

const Block3 = () => {
  const campaignsArr = [
    '● Paid Posts',
    '● Free Giveaways',
    '● Discount Vouchers',
    '● Shoutout Requests OR',
    '● Model & Promotional Bookings',
  ];

  return (
    <Wrapper>
      <Box sx={{ marginX: 'auto', marginTop: '-380px' }}>
        <HeaderGiliamsH71 textAlign="center">How Hyypp works?</HeaderGiliamsH71>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'space-around', md: 'space-between' },
          flexWrap: 'wrap',
          rowGap: '67px',
          marginTop: '48px',
          width: { xs: '100%', lg: '1140px' },
          paddingX: { xs: '20px', md: '60px', lg: 0 },
          marginX: 'auto',
        }}
      >
        <Box>
          <Box sx={{ marginBottom: '15px' }}>
            <Circle color={Color6} diameter="52px">
              <HeaderGillC7H22>01</HeaderGillC7H22>
            </Circle>
          </Box>

          <HeaderGiliamsC2H33>Create Promotions</HeaderGiliamsC2H33>

          <ParagraphGillC2H19>Launch a campaign for:</ParagraphGillC2H19>

          <Box sx={{ width: '300px' }}>
            <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
              {campaignsArr.map((item, index) => (
                <li key={`item-${index + 1}`}>
                  <ParagraphGillC2H19>{item}</ParagraphGillC2H19>
                </li>
              ))}
            </ul>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '15px' }}>
            <Circle color={Color6} diameter="52px">
              <HeaderGillC7H22>02</HeaderGillC7H22>
            </Circle>
          </Box>

          <HeaderGiliamsC2H33>Influencers Apply</HeaderGiliamsC2H33>

          <Box sx={{ width: '300px' }}>
            <ParagraphGillC2H19>
              Save time and money with HYYPP. Say goodbye to the hassle of
              manual outreach as local influencers now come to you. Sit back,
              relax, and watch as your brand attracts the perfect influencer
              collaborators.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '15px' }}>
            <Circle color={Color6} diameter="52px">
              <HeaderGillC7H22>03</HeaderGillC7H22>
            </Circle>
          </Box>

          <HeaderGiliamsC2H33>Select Influencers</HeaderGiliamsC2H33>

          <Box sx={{ width: '300px' }}>
            <ParagraphGillC2H19>
              Finding your ideal influencer match with HYYPP is easy. Our
              platform approves only the best-suited influencers to apply for
              collaborations. Take your time reviewing their profiles, then
              select the ones that align with your brand's vision and goals.
              Approve the influencers you'd like to work with and start creating
              impactful campaigns together.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '15px' }}>
            <Circle color={Color6} diameter="52px">
              <HeaderGillC7H22>04</HeaderGillC7H22>
            </Circle>
          </Box>

          <HeaderGiliamsC2H33>Product Promotions</HeaderGiliamsC2H33>

          <Box sx={{ width: '300px' }}>
            <ParagraphGillC2H19>
              Get going in under 60 seconds. HYYPP connects you with influencers
              based on your food or product, location, and the audience you want
              to reach.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '15px' }}>
            <Circle color={Color6} diameter="52px">
              <HeaderGillC7H22>05</HeaderGillC7H22>
            </Circle>
          </Box>

          <HeaderGiliamsC2H33>Brand goes Viral</HeaderGiliamsC2H33>

          <Box sx={{ width: '300px' }}>
            <ParagraphGillC2H19>
              Track upcoming visits and follow ups inside our apps dashboard. A
              great campaign with a great offer allows your brand to go viral
              quickly and cost-effectively.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box>
            <img
              src="images/block3-crown.svg"
              alt="sss"
              style={{ width: '300px', height: '313.33px' }}
            />
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Block3;
