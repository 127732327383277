import React from 'react';
import { Box } from '@mui/material';

const Circle = ({ children, color, diameter }) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        width: diameter,
        height: diameter,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
        paddingTop: '4px',
        paddingLeft: '2px',
      }}
    >
      {children}
    </Box>
  );
};

export default Circle;
