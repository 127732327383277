import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGiliamsH43 = ({ children, textAlign }) => {
  return (
    <Typography
      component="h4"
      sx={{
        fontFamily: 'Giliams',
        fontSize: { xs: '38px', lg: '43px' },
        fontWeight: 400,
        lineHeight: { xs: '47.89px', lg: '52.89px' },
        color: Color2,
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGiliamsH43;
