import React from 'react';
import { Box } from '@mui/material';
import PriceCard from '../basic/PriceCard';

const firstCardItemsArr = ['No Subscription', 'Pay as you go', '-', '-', '-'];

const secondCardItemsArr = [
  'Collab Insights',
  'Discounts',
  'Promotions',
  'Model Bookings',
  'Unlimited Campaigns',
];

const getStarted = <Box sx={{ paddingX: '36px' }}>Get Started</Box>;
const getStartedLink = process.env.REACT_APP_GET_STARTED;

const PricingBlock2 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '40px',
        marginTop: '81px',
        paddingX: { xs: '20px', lg: 0 },
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <PriceCard
        title="Free"
        price="$0"
        period="per month"
        itemsArr={firstCardItemsArr}
        bntTitle={getStarted}
        btnLink={getStartedLink}
      />
      <PriceCard
        title="Paid"
        price="$120"
        period="per month"
        itemsArr={secondCardItemsArr}
        bntTitle={getStarted}
        btnLink={getStartedLink}
      />
    </Box>
  );
};

export default PricingBlock2;
