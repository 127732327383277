import React from 'react';
import { Box } from '@mui/material';
import HeaderGiliamsH43 from './typography/HeaderGiliamsH43';
import { Color6 } from '../colors/Colors';
import HeaderGillC7H22 from './typography/HeaderGillC7H22';
import Circle from './basic/Circle';
import HeaderGillC1H22 from './typography/HeaderGillC1H22';
import ParagraphGillC2H19 from './typography/ParagraphGillC2H19';

const CampaignsSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        columnGap: '20px',
        rowGap: '40px',
        justifyContent: { xs: 'center', md: 'space-between' },
        alignItems: 'center',
        width: { xs: '100%', lg: '1140px' },
        paddingX: { xs: '20px', lg: 0 },
        marginX: 'auto',
        marginTop: { xs: '50px', lg: '142px' },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
      }}
    >
      <Box sx={{ maxWidth: '465px' }}>
        <img
          src="images/block6-campaigns.svg"
          alt="Campaigns"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>

      <Box sx={{ maxWidth: { xs: '390px', lg: '472px' }, marginTop: '14px' }}>
        <HeaderGiliamsH43>
          Launch campaigns in under 60 seconds
        </HeaderGiliamsH43>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '26px',
            marginTop: '36px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '15px' }}>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>01</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <HeaderGillC1H22>Create your offer</HeaderGillC1H22>
              </Box>
              <Box>
                <ParagraphGillC2H19>
                  Pay influencers in exchange for cash or product from your
                  store
                </ParagraphGillC2H19>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '15px' }}>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>02</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <HeaderGillC1H22>Schedule visits</HeaderGillC1H22>
              </Box>
              <Box>
                <ParagraphGillC2H19>
                  No more no shows. Sites and influencers know what’s happening
                  when and where.
                </ParagraphGillC2H19>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '15px' }}>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>03</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <HeaderGillC1H22>Measure results</HeaderGillC1H22>
              </Box>
              <Box>
                <ParagraphGillC2H19>
                  See which campaign worked best for you, based on real
                  engagement.
                </ParagraphGillC2H19>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignsSection;
