import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import FAQsBlock1 from '../components/faqsComponents/FAQsBlock1';
import FAQsBlock2 from '../components/faqsComponents/FAQsBlock2';

const FAQs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <FAQsBlock1 />
      <FAQsBlock2 />
    </Box>
  );
};

export default FAQs;
