import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Wrapper from '../components/basic/Wrapper';
import HeaderGiliamsH71 from '../components/typography/HeaderGiliamsH71';
import ParagraphGillC2H19 from '../components/typography/ParagraphGillC2H19';
import HeaderGiliamsC2H33 from '../components/typography/HeaderGiliamsC2H33';

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '54px',
          width: { xs: '100%', lg: '1142px' },
          marginTop: { xs: '30px', sm: '74px' },
          marginX: 'auto',
          paddingX: { xs: '20px', lg: 0 },
        }}
      >
        <Box
          sx={{
            backgroundImage:
              'linear-gradient(180deg, #121212 0%, #222222 100%)',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '456px',
            width: '100%',
            zIndex: '-1',
          }}
        />
        <Box>
          <HeaderGiliamsH71 textAlign="center">
            Terms & Conditions
          </HeaderGiliamsH71>
        </Box>
        <Box sx={{ maxWidth: '1080px', marginX: 'auto', marginTop: '-33px' }}>
          <ParagraphGillC2H19 textAlign="center">
            Last Updated: 10/20/2022
          </ParagraphGillC2H19>
          <ParagraphGillC2H19 textAlign="center">
            Please read these Terms and Conditions carefully before using the
            services of Hyypp Inc
          </ParagraphGillC2H19>
        </Box>

        <Box sx={{ marginTop: '10px' }}>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>
              What Information do we collect?
            </HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              We collect personal information that you provide to us such as
              name, email, contact information, security data, location and
              payment information. We collect personal information that you
              voluntarily provide to us when registering at the Services or
              “Platform”, expressing an interest in obtaining information about
              us or our products and services, when participating in activities
              on the Services or “Platform” (such as posting messages on our
              “Platform”, accepting promotions) or otherwise contacting us. The
              personal information that we collect depends on the context of
              your interactions with us and the Services or “Platform”, the
              choices you make and the products and feature you use. The
              personal information we collect can include the following:
              Credentials – We collect login confirmation, email used to
              validate and send login link, security information used for
              authentication and account access.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>Agreement to Terms</HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              These terms of use constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (“you”)
              and engage Beyond, doing business as Hyypp Inc (“Hyypp”, “we”,
              “us”, “our”), concerning your access to and use of the website as
              well as any other media form, media channel, mobile website or
              mobile application related, linked, or otherwise connected thereto
              (collectively, the “Site”). You agree that by accessing the Site,
              you have read, understood and agreed to be bound by all of these
              Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE,
              THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
              DISCONTINUE USE IMMEDIATELY.
            </ParagraphGillC2H19>

            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                Supplemental terms and conditions or documents that may be
                posted on the Site from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Terms
                of Use at any time and for any reason. We will alert you about
                any changes by updating the “Last Updated” date of these Terms
                of Use, and you waive any right to receive specific notice of
                each such change. It is your responsibility to periodically
                review these Terms of Use to stay informed of updates. You will
                be subject to, and will be deemed to have been made aware of and
                to have accepted, the changes in any revised ‘Terms of Use by
                your continued use of the site and application after the date
                such revised Terms of Use are posted.
              </ParagraphGillC2H19>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                The information provided on the Site and Mobile Application is
                not intended for distribution to or use by any person or entity
                in any jurisdiction or country where such distribution or use
                would be contrary to law or regulation or which would subject us
                to any registration requirement within such jurisdiction or
                country. Accordingly, those persons who choose to access the
                Site from other locations do so on their own initiative and are
                solely responsible for compliance with local laws, if and to the
                extent local laws are applicable.
              </ParagraphGillC2H19>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                The Site is intended for users who are at least 13 years of age.
                All users who are minors in the jurisdiction in which they
                reside (generally under the age of 18) must have the permission
                of, and be directly supervised by, their parent or guardian to
                use the Site or Mobile Application. If you are a minor, you must
                have your parent or guardian read and agree to these Terms of
                Use prior to you using the Site or Mobile application.
              </ParagraphGillC2H19>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>
              Definitions and Modified Terms
            </HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              In these Terms and Conditions of Use, the following capitalized
              terms shall have the following meanings, except where the context
              otherwise requires:
            </ParagraphGillC2H19>

            <ParagraphGillC2H19>
              “Platform” – means and refers to the Foodie Touch mobile
              application as well as the website
            </ParagraphGillC2H19>

            <ParagraphGillC2H19>www.hyypp.com</ParagraphGillC2H19>

            <ParagraphGillC2H19>
              “Influencers” – means and refers to an individual or groups who
              have the power to affect other people’s decisions from online
              dialogues, posted content, and social content. A foodie is
              considered an “influencer” that specializes with food marketing.
            </ParagraphGillC2H19>

            <ParagraphGillC2H19>
              “Users” – means and refers to individuals using the “Platform”
            </ParagraphGillC2H19>

            <ParagraphGillC2H19>
              “Content” – means and refers to source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs and graphic on the “Platform”
            </ParagraphGillC2H19>

            <ParagraphGillC2H19>
              “Contributions” – means and refers to content which you provide
              “Foodie Touch Inc” through the “Platform”.
            </ParagraphGillC2H19>

            <ParagraphGillC2H19>
              “HYYPP Inc” – means and refers to the business entity of the
              “Platform”. “HYYPP” can also be used as “Us”, “We”, “Our”.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>Intellectual Property</HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              Unless otherwise indicated, the “Platform” is our proprietary
              property and all source code, databases, functionality, software,
              website designs, audio, video, text, photographs and graphic on
              the “Platform” (collectively, the “Content ”). The “Content” is
              provided on the “Platform” “As Is” for your information and
              personal use only. Except as expressly provided in these Terms of
              Use, no part of the “Platform” and no part of the “Content” may be
              copied, reproduced, aggregated, republished, uploaded, posted,
              publicly displayed, encoded, translated, transmitted, distributed,
              sold, licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </ParagraphGillC2H19>
            <Box sx={{ marginTop: '30px' }}>
              <ParagraphGillC2H19>
                Provided that you are eligible to use the “Platform”, you are
                granted a limited license to access and use the “Platform”, you
                are granted limited license to access and use the “Platform” to
                download, print a copy of any portion of the Content to which
                you have property gained access solely for your personal,
                non-commercial use. We reserve all right not expressly granted
                to you in and to the “Platform” and the “Content”.
              </ParagraphGillC2H19>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>User Representations</HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              By using the “Platform”, you represent and warrant that: (1) all
              registration information you submit will be true, accurate,
              current, and complete; (2) you will maintain the accuracy of such
              registration information as necessary; (3) you have the legal
              capacity and you agree to comply with these Terms of Use; (4) you
              are not under the age of 13; (5) you are not a minor in the
              jurisdiction in which you reside, or if a minor, you have received
              parental permission to use the “Platform”; (6) you will not access
              the “Platform” through automated or non-human means, whether
              through a bot, script or otherwise; (7) you will not use the
              “Platform” for any illegal or unauthorized purpose; and (8) your
              use of the “Platform” will not violate any applicable law or
              regulation within your jurisdiction or Country. If you provide any
              information that is untrue, inaccurate, not current, or
              incomplete, we have the right to suspend or terminate your account
              and refuse any and all current or future use of the “Platform”.
            </ParagraphGillC2H19>
          </Box>
        </Box>

        <Box>
          <Box sx={{ marginBottom: '17px' }}>
            <HeaderGiliamsC2H33>Mobile Application License</HeaderGiliamsC2H33>
          </Box>
          <Box>
            <ParagraphGillC2H19>
              If you access the “Platform”, then we grant you a revocable,
              non-exclusive, non-transferable, limited right to install and use
              the “Platform” on wireless electronic devices owned or controlled
              by you, and to access and use the “Platform” on such devices
              strictly in accordance with the terms and conditions of this
              “Platform” license contained these Terms of Use. You shall not:
              (1) decompile, reverse engineer, disassemble, attempt to derive
              the source code of, or decrypt the application; (2) make any
              modification, adaption, improvement, enhancement, translation, or
              derivative work from application, (3) violate any applicable laws,
              rules, or regulations in connection with your access or use of the
              application;(4) remove, alter, or obscure any proprietary notice
              (including any notice of copyright or trademark) posted by us or
              the licensors of the application; (5) use the application for any
              revenue generating endeavor, commercial enterprise, or other
              purpose for which it is not designed or intended (6) make the
              application available over a network other environment permitting
              access or use by multiple devices or users at the same time; (7)
              use the application for creating a product, service, or software
              that is, directly or indirectly, competitive with or in any way a
              substitute for the application; (8) use the application to send
              automated queries to any website or to send any unsolicited
              commercial email; or (9) use any proprietary information or any of
              our interfaces or our other intellectual property in the design,
              development, manufacture, licensing, or distribution of any
              applications, accessories, or devices for use with the
              application.
            </ParagraphGillC2H19>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default TermsConditions;
