import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomizedButton2 = styled(Button)({
  border: '1px solid #A38D5D',
  borderRadius: '82px',
  padding: '15px 49px 15px 51px',
  color: '#EAC77A',
  textTransform: 'none',
  fontFamily: '"Gill Sans", sans-serif',
  fontWeight: 500,
  fontSize: '19px',
  lineHeight: '24px',
  fontStyle: 'normal',
});

export default function RoundedOutlinedButton2({ children, link }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${link}`);
  };

  return (
    <CustomizedButton2>
      <Box onClick={handleClick}>{children}</Box>
    </CustomizedButton2>
  );
}
