import React from 'react';
import { Typography } from '@mui/material';

const HeaderGillH18 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '26.46px',
        color: 'rgba(255,255,255,0.8)',
        textDecoration: 'none',
        textTransform: 'none',
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGillH18;
