import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import CollabsBlock1 from '../components/collabsComponents/CollabsBlock1';
import InfluencersSection from '../components/InfluencersSection';
import InstagramSection from '../components/InstagramSection';
import CollabsBlock2 from '../components/collabsComponents/CollabsBlock2';
import CollabsBlock3 from '../components/collabsComponents/CollabsBlock3';
import CollabsBlock4 from '../components/collabsComponents/CollabsBlock4';
import CollabsBlock5 from '../components/collabsComponents/CollabsBlock5';
import ScrollAnimation from 'react-animate-on-scroll';

const Collabs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <CollabsBlock1 />

      <CollabsBlock2 />

      <ScrollAnimation animateIn="zoomIn">
        <CollabsBlock3 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInLeft">
        <CollabsBlock4 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInLeft">
        <CollabsBlock5 />
      </ScrollAnimation>

      <ScrollAnimation animateIn="zoomIn">
        <InfluencersSection />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <InstagramSection showTitle={true} />
      </ScrollAnimation>
    </Box>
  );
};

export default Collabs;
