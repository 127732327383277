import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGillC1H22 = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: '35.2px',
        color: Color2,
        textDecoration: 'none',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGillC1H22;
