import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGiliamsH35 = ({ children, textAlign }) => {
  return (
    <Typography
      component="h6"
      sx={{
        fontFamily: 'Giliams',
        fontSize: { xs: '30px', lg: '35px' },
        fontWeight: 400,
        lineHeight: { xs: '34px', lg: '39px' },
        color: Color2,
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGiliamsH35;
