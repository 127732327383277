import React from 'react';
import { Typography } from '@mui/material';

const HeaderGillH20 = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '32.6px',
        color: '#FFF',
        textDecoration: 'none',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGillH20;
