import React from 'react';
import { Box } from '@mui/material';
import Circle from '../basic/Circle';
import { Color6 } from '../../colors/Colors';
import HeaderGillC7H22 from '../typography/HeaderGillC7H22';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';

const HowItWorksBlock2 = () => {
  const promotionsArr = [
    '● Paid posts',
    '● Free Giveaways',
    '● Discount Vouchers',
    '● Shoutout Request OR',
    '● Model Bookings',
  ];

  return (
    <Box
      sx={{
        maxWidth: '1007px',
        marginX: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '102px',
        paddingX: { xs: '20px', lg: 0 },
        flexDirection: { xs: 'column', md: 'row' },
        columnGap: '20px',
        rowGap: '40px',
      }}
    >
      <Box>
        <Box sx={{ marginBottom: '15px' }}>
          <Circle color={Color6} diameter="52px">
            <HeaderGillC7H22>01</HeaderGillC7H22>
          </Circle>
        </Box>

        <HeaderGiliamsC2H33>Create Promotions</HeaderGiliamsC2H33>

        <ParagraphGillC2H19>Launch a campaign for:</ParagraphGillC2H19>

        <Box sx={{ width: '300px' }}>
          <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
            {promotionsArr.map((item, index) => (
              <li key={`item-${index + 1}`}>
                <ParagraphGillC2H19>{item}</ParagraphGillC2H19>
              </li>
            ))}
          </ul>
        </Box>
      </Box>

      <Box sx={{ maxWidth: '485px', width: { xs: '100%', sm: '485px' } }}>
        <img
          src="images/how-it-works/block2-promotions.svg"
          alt="Create Promotions"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default HowItWorksBlock2;
