import React from 'react';
import { Box } from '@mui/material';
import Wrapper from '../basic/Wrapper';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';

const CollabsBlock1 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          backgroundImage: 'linear-gradient(180deg, #121212 0%, #222222 100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '456px',
          width: '100%',
          zIndex: '-1',
        }}
      />
      <Box sx={{ marginTop: '77px', paddingX: { xs: '20px', md: 0 } }}>
        <HeaderGiliamsH71 textAlign="center">Collab Insights</HeaderGiliamsH71>
      </Box>
      <Box
        sx={{
          maxWidth: '723px',
          marginX: 'auto',
          paddingX: { xs: '20px', md: 0 },
        }}
      >
        <ParagraphGillC2H19 textAlign="center">
          Discover the Ultimate Marketing Allies - Local Influencers Who Live
          and Breathe Your Brand! With Thousands of Engaged Followers in Your
          Community, They'll Help Your Message Soar
        </ParagraphGillC2H19>
      </Box>
    </Wrapper>
  );
};

export default CollabsBlock1;
