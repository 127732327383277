import React from 'react';
import { Typography } from '@mui/material';

const HeaderGillH23 = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '23px',
        fontWeight: 600,
        lineHeight: '37.49px',
        color: '#FFF',
        textDecoration: 'none',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGillH23;
