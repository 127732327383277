const countryCodes = [
  { name: 'Afghanistan', dial_code: '+93', code: 'AF', phLength: 9 },
  { name: 'Aland Islands', dial_code: '+358', code: 'AX', phLength: 10 },
  { name: 'Albania', dial_code: '+355', code: 'AL', phLength: 8 },
  { name: 'Algeria', dial_code: '+213', code: 'DZ', phLength: 9 },
  { name: 'AmericanSamoa', dial_code: '+1684', code: 'AS', phLength: 7 },
  { name: 'Andorra', dial_code: '+376', code: 'AD', phLength: 6 },
  { name: 'Angola', dial_code: '+244', code: 'AO', phLength: 9 },
  { name: 'Anguilla', dial_code: '+1264', code: 'AI', phLength: 10 },
  { name: 'Antarctica', dial_code: '+672', code: 'AQ', phLength: 6 },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG',
    phLength: 10,
  },
  { name: 'Argentina', dial_code: '+54', code: 'AR', phLength: 8 },
  { name: 'Armenia', dial_code: '+374', code: 'AM', phLength: 8 },
  { name: 'Aruba', dial_code: '+297', code: 'AW', phLength: 7 },
  { name: 'Australia', dial_code: '+61', code: 'AU', phLength: 9 },
  { name: 'Austria', dial_code: '+43', code: 'AT', phLength: 10 },
  { name: 'Azerbaijan', dial_code: '+994', code: 'AZ', phLength: 9 },
  { name: 'Bahamas', dial_code: '+1242', code: 'BS', phLength: 10 },
  { name: 'Bahrain', dial_code: '+973', code: 'BH', phLength: 8 },
  { name: 'Bangladesh', dial_code: '+880', code: 'BD', phLength: 10 },
  { name: 'Barbados', dial_code: '+1246', code: 'BB', phLength: 10 },
  { name: 'Belarus', dial_code: '+375', code: 'BY', phLength: 9 },
  { name: 'Belgium', dial_code: '+32', code: 'BE', phLength: 9 },
  { name: 'Belize', dial_code: '+501', code: 'BZ', phLength: 7 },
  { name: 'Benin', dial_code: '+229', code: 'BJ', phLength: 9 },
  { name: 'Bermuda', dial_code: '+1441', code: 'BM', phLength: 10 },
  { name: 'Bhutan', dial_code: '+975', code: 'BT', phLength: 7 },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'BO',
    phLength: 8,
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
    phLength: 8,
  },
  { name: 'Botswana', dial_code: '+267', code: 'BW', phLength: 8 },
  { name: 'Brazil', dial_code: '+55', code: 'BR', phLength: 11 },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO',
    phLength: 7,
  },
  { name: 'Brunei Darussalam', dial_code: '+673', code: 'BN', phLength: 7 },
  { name: 'Bulgaria', dial_code: '+359', code: 'BG', phLength: 9 },
  { name: 'Burkina Faso', dial_code: '+226', code: 'BF', phLength: 8 },
  { name: 'Burundi', dial_code: '+257', code: 'BI', phLength: 8 },
  { name: 'Cambodia', dial_code: '+855', code: 'KH', phLength: 9 },
  { name: 'Cameroon', dial_code: '+237', code: 'CM', phLength: 9 },
  { name: 'Canada', dial_code: '+1', code: 'CA', phLength: 10 },
  { name: 'Cape Verde', dial_code: '+238', code: 'CV', phLength: 7 },
  {
    name: 'Cayman Islands',
    dial_code: '+345',
    code: 'KY',
    phLength: 10,
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
    phLength: 8,
  },
  { name: 'Chad', dial_code: '+235', code: 'TD', phLength: 8 },
  { name: 'Chile', dial_code: '+56', code: 'CL', phLength: 9 },
  { name: 'China', dial_code: '+86', code: 'CN', phLength: 13 },
  { name: 'Christmas Island', dial_code: '+61', code: 'CX', phLength: 9 },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC',
    phLength: 9,
  },
  { name: 'Colombia', dial_code: '+57', code: 'CO', phLength: 10 },
  { name: 'Comoros', dial_code: '+269', code: 'KM', phLength: 7 },
  { name: 'Congo', dial_code: '+242', code: 'CG', phLength: 9 },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'CD',
    phLength: 9,
  },
  { name: 'Cook Islands', dial_code: '+682', code: 'CK', phLength: 5 },
  { name: 'Costa Rica', dial_code: '+506', code: 'CR', phLength: 8 },
  { name: "Cote d'Ivoire", dial_code: '+225', code: 'CI', phLength: 10 },
  { name: 'Croatia', dial_code: '+385', code: 'HR', phLength: 9 },
  { name: 'Cuba', dial_code: '+53', code: 'CU' },
  { name: 'Cyprus', dial_code: '+357', code: 'CY', phLength: 8 },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    phLength: 9,
  },
  { name: 'Denmark', dial_code: '+45', code: 'DK', phLength: 8 },
  { name: 'Djibouti', dial_code: '+253', code: 'DJ', phLength: 8 },
  { name: 'Dominica', dial_code: '+1767', code: 'DM', phLength: 10 },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'DO',
    phLength: 10,
  },
  { name: 'Ecuador', dial_code: '+593', code: 'EC', phLength: 9 },
  { name: 'Egypt', dial_code: '+20', code: 'EG', phLength: 10 },
  { name: 'El Salvador', dial_code: '+503', code: 'SV', phLength: 8 },
  { name: 'Equatorial Guinea', dial_code: '+240', code: 'GQ', phLength: 12 },
  { name: 'Eritrea', dial_code: '+291', code: 'ER', phLength: 10 },
  { name: 'Estonia', dial_code: '+372', code: 'EE', phLength: 8 },
  { name: 'Ethiopia', dial_code: '+251', code: 'ET', phLength: 12 },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK',
    phLength: 5,
  },
  { name: 'Faroe Islands', dial_code: '+298', code: 'FO', phLength: 5 },
  { name: 'Fiji', dial_code: '+679', code: 'FJ' },
  { name: 'Finland', dial_code: '+358', code: 'FI' },
  { name: 'France', dial_code: '+33', code: 'FR' },
  { name: 'French Guiana', dial_code: '+594', code: 'GF', phLength: 9 },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
    phLength: 6,
  },
  { name: 'Gabon', dial_code: '+241', code: 'GA', phLength: 7 },
  { name: 'Gambia', dial_code: '+220', code: 'GM', phLength: 7 },
  { name: 'Georgia', dial_code: '+995', code: 'GE', phLength: 9 },
  { name: 'Germany', dial_code: '+49', code: 'DE', phLength: 10 },
  { name: 'Ghana', dial_code: '+233', code: 'GH', phLength: 9 },
  { name: 'Gibraltar', dial_code: '+350', code: 'GI', phLength: 8 },
  { name: 'Greece', dial_code: '+30', code: 'GR', phLength: 10 },
  { name: 'Greenland', dial_code: '+299', code: 'GL', phLength: 6 },
  { name: 'Grenada', dial_code: '+1473', code: 'GD', phLength: 10 },
  { name: 'Guadeloupe', dial_code: '+590', code: 'GP', phLength: 9 },
  { name: 'Guam', dial_code: '+1671', code: 'GU', phLength: 10 },
  { name: 'Guatemala', dial_code: '+502', code: 'GT', phLength: 8 },
  { name: 'Guernsey', dial_code: '+44', code: 'GG', phLength: 10 },
  { name: 'Guinea', dial_code: '+224', code: 'GN', phLength: 8 },
  { name: 'Guinea-Bissau', dial_code: '+245', code: 'GW', phLength: 9 },
  { name: 'Guyana', dial_code: '+595', code: 'GY', phLength: 10 },
  { name: 'Haiti', dial_code: '+509', code: 'HT', phLength: 9 },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'VA',
    phLength: 10,
  },
  { name: 'Honduras', dial_code: '+504', code: 'HN', phLength: 8 },
  { name: 'Hong Kong', dial_code: '+852', code: 'HK', phLength: 8 },
  { name: 'Hungary', dial_code: '+36', code: 'HU', phLength: 9 },
  { name: 'Iceland', dial_code: '+354', code: 'IS', phLength: 7 },
  { name: 'India', dial_code: '+91', code: 'IN', phLength: 10 },
  { name: 'Indonesia', dial_code: '+62', code: 'ID', phLength: 9 },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    code: 'IR',
    phLength: 11,
  },
  { name: 'Iraq', dial_code: '+964', code: 'IQ', phLength: 7 },
  { name: 'Ireland', dial_code: '+353', code: 'IE', phLength: 9 },
  { name: 'Isle of Man', dial_code: '+44', code: 'IM', phLength: 10 },
  { name: 'Israel', dial_code: '+972', code: 'IL', phLength: 9 },
  { name: 'Italy', dial_code: '+39', code: 'IT', phLength: 10 },
  { name: 'Jamaica', dial_code: '+1876', code: 'JM', phLength: 10 },
  { name: 'Japan', dial_code: '+81', code: 'JP', phLength: 10 },
  { name: 'Jersey', dial_code: '+44', code: 'JE', phLength: 10 },
  { name: 'Jordan', dial_code: '+962', code: 'JO', phLength: 9 },
  { name: 'Kazakhstan', dial_code: '+77', code: 'KZ', phLength: 10 },
  { name: 'Kenya', dial_code: '+254', code: 'KE', phLength: 10 },
  { name: 'Kiribati', dial_code: '+686', code: 'KI', phLength: 8 },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    code: 'KP',
    phLength: 8,
  },
  {
    name: 'Korea, Republic of South Korea',
    dial_code: '+82',
    code: 'KR',
    phLength: 8,
  },
  { name: 'Kuwait', dial_code: '+965', code: 'KW', phLength: 8 },
  { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG', phLength: 8 },
  { name: 'Laos', dial_code: '+856', code: 'LA', phLength: 8 },
  { name: 'Latvia', dial_code: '+371', code: 'LV', phLength: 8 },
  { name: 'Lebanon', dial_code: '+961', code: 'LB', phLength: 7 },
  { name: 'Lesotho', dial_code: '+266', code: 'LS', phLength: 8 },
  { name: 'Liberia', dial_code: '+231', code: 'LR', phLength: 7 },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY',
    phLength: 9,
  },
  { name: 'Liechtenstein', dial_code: '+423', code: 'LI', phLength: 7 },
  { name: 'Lithuania', dial_code: '+370', code: 'LT', phLength: 8 },
  { name: 'Luxembourg', dial_code: '+352', code: 'LU', phLength: 9 },
  { name: 'Macao', dial_code: '+853', code: 'MO', phLength: 8 },
  { name: 'Macedonia', dial_code: '+389', code: 'MK', phLength: 8 },
  { name: 'Madagascar', dial_code: '+261', code: 'MG', phLength: 7 },
  { name: 'Malawi', dial_code: '+265', code: 'MW', phLength: 10 },
  { name: 'Malaysia', dial_code: '+60', code: 'MY', phLength: 7 },
  { name: 'Maldives', dial_code: '+960', code: 'MV', phLength: 7 },
  { name: 'Mali', dial_code: '+223', code: 'ML', phLength: 8 },
  { name: 'Malta', dial_code: '+356', code: 'MT', phLength: 8 },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
    phLength: 7,
  },
  { name: 'Martinique', dial_code: '+596', code: 'MQ', phLength: 9 },
  { name: 'Mauritania', dial_code: '+222', code: 'MR', phLength: 8 },
  { name: 'Mauritius', dial_code: '+230', code: 'MU', phLength: 8 },
  { name: 'Mayotte', dial_code: '+262', code: 'YT', phLength: 7 },
  { name: 'Mexico', dial_code: '+52', code: 'MX', phLength: 10 },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    code: 'FM',
    phLength: 7,
  },
  { name: 'Moldova', dial_code: '+373', code: 'MD', phLength: 8 },
  { name: 'Monaco', dial_code: '+377', code: 'MC', phLength: 8 },
  { name: 'Mongolia', dial_code: '+976', code: 'MN', phLength: 9 },
  { name: 'Montenegro', dial_code: '+382', code: 'ME', phLength: 8 },
  { name: 'Montserrat', dial_code: '+1664', code: 'MS', phLength: 10 },
  { name: 'Morocco', dial_code: '+212', code: 'MA', phLength: 9 },
  { name: 'Mozambique', dial_code: '+258', code: 'MZ', phLength: 12 },
  { name: 'Myanmar', dial_code: '+95', code: 'MM', phLength: 8 },
  { name: 'Namibia', dial_code: '+264', code: 'NA', phLength: 8 },
  { name: 'Nauru', dial_code: '+674', code: 'NR', phLength: 7 },
  { name: 'Nepal', dial_code: '+977', code: 'NP', phLength: 10 },
  { name: 'Netherlands', dial_code: '+31', code: 'NL', phLength: 9 },
  { name: 'Netherlands Antilles', dial_code: '+599', code: 'AN' },
  { name: 'New Caledonia', dial_code: '+687', code: 'NC', phLength: 6 },
  { name: 'New Zealand', dial_code: '+64', code: 'NZ', phLength: 9 },
  { name: 'Nicaragua', dial_code: '+505', code: 'NI', phLength: 8 },
  { name: 'Niger', dial_code: '+227', code: 'NE', phLength: 8 },
  { name: 'Nigeria', dial_code: '+234', code: 'NG', phLength: 8 },
  { name: 'Niue', dial_code: '+683', code: 'NU', phLength: 4 },
  { name: 'Norfolk Island', dial_code: '+672', code: 'NF', phLength: 6 },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'MP',
    phLength: 10,
  },
  { name: 'Norway', dial_code: '+47', code: 'NO', phLength: 8 },
  { name: 'Oman', dial_code: '+968', code: 'OM', phLength: 8 },
  { name: 'Pakistan', dial_code: '+92', code: 'PK', phLength: 10 },
  { name: 'Palau', dial_code: '+680', code: 'PW', phLength: 7 },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    code: 'PS',
    phLength: 9,
  },
  { name: 'Panama', dial_code: '+507', code: 'PA', phLength: 8 },
  { name: 'Papua New Guinea', dial_code: '+675', code: 'PG', phLength: 8 },
  { name: 'Paraguay', dial_code: '+595', code: 'PY', phLength: 9 },
  { name: 'Peru', dial_code: '+51', code: 'PE', phLength: 9 },
  { name: 'Philippines', dial_code: '+63', code: 'PH', phLength: 10 },
  { name: 'Pitcairn', dial_code: '+872', code: 'PN', phLength: 9 },
  { name: 'Poland', dial_code: '+48', code: 'PL', phLength: 9 },
  { name: 'Portugal', dial_code: '+351', code: 'PT', phLength: 9 },
  { name: 'Puerto Rico', dial_code: '+1939', code: 'PR', phLength: 10 },
  { name: 'Qatar', dial_code: '+974', code: 'QA', phLength: 8 },
  { name: 'Romania', dial_code: '+40', code: 'RO', phLength: 10 },
  { name: 'Russia', dial_code: '+7', code: 'RU', phLength: 10 },
  { name: 'Rwanda', dial_code: '+250', code: 'RW', phLength: 6 },
  { name: 'Reunion', dial_code: '+262', code: 'RE', phLength: 9 },
  { name: 'Saint Barthelemy', dial_code: '+590', code: 'BL', phLength: 9 },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH',
    phLength: 5,
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'KN',
    phLength: 7,
  },
  { name: 'Saint Lucia', dial_code: '+1758', code: 'LC', phLength: 10 },
  { name: 'Saint Martin', dial_code: '+590', code: 'MF', phLength: 9 },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
    phLength: 6,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'VC',
    phLength: 10,
  },
  { name: 'Samoa', dial_code: '+685', code: 'WS', phLength: 5 },
  { name: 'San Marino', dial_code: '+378', code: 'SM', phLength: 6 },
  { name: 'Sao Tome and Principe', dial_code: '+239', code: 'ST', phLength: 7 },
  { name: 'Saudi Arabia', dial_code: '+966', code: 'SA', phLength: 9 },
  { name: 'Senegal', dial_code: '+221', code: 'SN', phLength: 9 },
  { name: 'Serbia', dial_code: '+381', code: 'RS', phLength: 8 },
  { name: 'Seychelles', dial_code: '+248', code: 'SC', phLength: 7 },
  { name: 'Sierra Leone', dial_code: '+232', code: 'SL', phLength: 8 },
  { name: 'Singapore', dial_code: '+65', code: 'SG', phLength: 8 },
  { name: 'Slovakia', dial_code: '+421', code: 'SK', phLength: 9 },
  { name: 'Slovenia', dial_code: '+386', code: 'SI', phLength: 9 },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
    phLength: 7,
  },
  { name: 'Somalia', dial_code: '+252', code: 'SO', phLength: 8 },
  { name: 'South Africa', dial_code: '+27', code: 'ZA', phLength: 9 },
  { name: 'South Sudan', dial_code: '+211', code: 'SS', phLength: 9 },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
    phLength: 5,
  },
  { name: 'Spain', dial_code: '+34', code: 'ES', phLength: 9 },
  { name: 'Sri Lanka', dial_code: '+94', code: 'LK', phLength: 7 },
  { name: 'Sudan', dial_code: '+249', code: 'SD', phLength: 7 },
  { name: 'Suriname', dial_code: '+597', code: 'SR', phLength: 7 },
  { name: 'Svalbard and Jan Mayen', dial_code: '+47', code: 'SJ', phLength: 8 },
  { name: 'Swaziland', dial_code: '+268', code: 'SZ', phLength: 7 },
  { name: 'Sweden', dial_code: '+46', code: 'SE', phLength: 7 },
  { name: 'Switzerland', dial_code: '+41', code: 'CH', phLength: 9 },
  { name: 'Syrian Arab Republic', dial_code: '+963', code: 'SY' },
  { name: 'Taiwan', dial_code: '+886', code: 'TW', phLength: 9 },
  { name: 'Tajikistan', dial_code: '+992', code: 'TJ', phLength: 6 },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    code: 'TZ',
    phLength: 9,
  },
  { name: 'Thailand', dial_code: '+66', code: 'TH', phLength: 9 },
  { name: 'Timor-Leste', dial_code: '+670', code: 'TL', phLength: 8 },
  { name: 'Togo', dial_code: '+228', code: 'TG', phLength: 8 },
  { name: 'Tokelau', dial_code: '+690', code: 'TK', phLength: 5 },
  { name: 'Tonga', dial_code: '+676', code: 'TO', phLength: 8 },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'TT',
    phLength: 10,
  },
  { name: 'Tunisia', dial_code: '+216', code: 'TN', phLength: 8 },
  { name: 'Turkey', dial_code: '+90', code: 'TR', phLength: 10 },
  { name: 'Turkmenistan', dial_code: '+993', code: 'TM', phLength: 8 },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'TC',
    phLength: 10,
  },
  { name: 'Tuvalu', dial_code: '+688', code: 'TV', phLength: 5 },
  { name: 'Uganda', dial_code: '+256', code: 'UG', phLength: 9 },
  { name: 'Ukraine', dial_code: '+380', code: 'UA', phLength: 9 },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
    phLength: 9,
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    phLength: 10,
  },
  { name: 'United States', dial_code: '+1', code: 'US', phLength: 10 },
  { name: 'Uruguay', dial_code: '+598', code: 'UY', phLength: 8 },
  { name: 'Uzbekistan', dial_code: '+998', code: 'UZ', phLength: 9 },
  { name: 'Vanuatu', dial_code: '+678', code: 'VU', phLength: 8 },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    code: 'VE',
    phLength: 7,
  },
  { name: 'Vietnam', dial_code: '+84', code: 'VN', phLength: 9 },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1284',
    code: 'VG',
    phLength: 7,
  },
  { name: 'Virgin Islands, U.S.', dial_code: '+1340', code: 'VI', phLength: 7 },
  { name: 'Wallis and Futuna', dial_code: '+681', code: 'WF', phLength: 6 },
  { name: 'Yemen', dial_code: '+967', code: 'YE', phLength: 9 },
  { name: 'Zambia', dial_code: '+260', code: 'ZM', phLength: 9 },
  { name: 'Zimbabwe', dial_code: '+263', code: 'ZW', phLength: 9 },
];
console.log('countryCodes', countryCodes.length);
export default countryCodes;
