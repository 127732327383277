import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGiliamsH71 = ({ children, textAlign }) => {
  return (
    <Typography
      component="h2"
      sx={{
        fontFamily: 'Giliams',
        fontSize: { xs: '36px', sm: '55px', lg: '71px' },
        fontWeight: 400,
        lineHeight: { xs: '48px', sm: '68.1px', md: '78.1px' },
        color: Color2,
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGiliamsH71;
