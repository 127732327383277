import React from 'react';
import { Box } from '@mui/material';
import HeaderGillH18 from '../typography/HeaderGillH18';
import { Link } from 'react-router-dom';
import HeaderGillC2H11 from '../typography/HeaderGillC2H11';

const FooterCopyright = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: { xs: '20px', sm: '45px' },
        paddingX: { xs: '20px', lg: 0 },
        marginTop: { xs: '21px', sm: '42px' },
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      <Box>
        <HeaderGillH18>© 2023 HYYPP. All rights reserved.</HeaderGillH18>

        <Box sx={{ marginTop: '4px' }}>
          <HeaderGillC2H11>
            All product names, trademarks and registered trademarks are property
            of their respective owners.
          </HeaderGillC2H11>

          <HeaderGillC2H11>
            All company, product and service names used in this website are for
            identification purposes only.
          </HeaderGillC2H11>

          <HeaderGillC2H11>
            Use of these names, trademarks and brands does not imply endorsement
            or affiliation.
          </HeaderGillC2H11>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '10px',
          marginTop: { xs: '20px', md: '-20px' },
        }}
      >
        <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
          <HeaderGillH18>Privacy Policy</HeaderGillH18>
        </Link>
        <Box
          sx={{
            width: '6px',
            height: '6px',
            backgroundColor: 'rgba(217, 217, 217, 0.22)',
            borderRadius: '50px',
          }}
        />
        <Link to="/terms-and-conditions" style={{ textDecoration: 'none' }}>
          <HeaderGillH18>Term of Services</HeaderGillH18>
        </Link>
      </Box>
    </Box>
  );
};

export default FooterCopyright;
