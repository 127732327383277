import React from 'react';
import { Typography } from '@mui/material';

const ParagraphGillH14 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#222',
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default ParagraphGillH14;
