import React from 'react';
import { Box } from '@mui/material';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';
import Wrapper from '../basic/Wrapper';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import InfluencerSmallCard from '../basic/InfluencerSmallCard';

const InfluencersBlock1 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          position: 'relative',
          height: '1628px',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '24px',
            marginTop: '110px',
            position: 'absolute',
            top: 0,
            left: { xs: '-354px', sm: '-7px' },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-01.svg")',
                width: '345.44px',
                height: '405.86px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            />
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-05.svg")',
                width: '345.44px',
                height: '407px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            >
              <InfluencerSmallCard
                avatar="block1-avatar-01.svg"
                name="Bessie Cooper"
                user="@bessie"
              />
            </Box>
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-09.svg")',
                width: '345.44px',
                height: '474.27px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            >
              <InfluencerSmallCard
                avatar="block1-avatar-05.svg"
                name="Cameron Williamson"
                user="@cameron-will"
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-02.svg")',
                width: '345.44px',
                height: '461.72px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            />
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-06.svg")',
                width: '345.44px',
                height: '461.72px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            >
              <InfluencerSmallCard
                avatar="block1-avatar-02.svg"
                name="Darrell Steward"
                user="@darrel-steward"
              />
            </Box>
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-10.svg")',
                width: '345.44px',
                height: '533.55px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            >
              <InfluencerSmallCard
                avatar="block1-avatar-06.svg"
                name="Theresa Webb"
                user="@theresa-webb"
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-03.svg")',
                width: '345.44px',
                height: '318.08px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            />
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-07.svg")',
                width: '345.44px',
                height: '497.07px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            >
              <InfluencerSmallCard
                avatar="block1-avatar-03.svg"
                name="Esther Howard"
                user="@esther-howard"
              />
            </Box>
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-11.svg")',
                width: '345.44px',
                height: '575.73px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            >
              <InfluencerSmallCard
                avatar="block1-avatar-07.svg"
                name="Courtney Henry"
                user="@courtney-henry"
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-04.svg")',
                width: '345.44px',
                height: '482.25px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            />
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-08.svg")',
                width: '345.44px',
                height: '318.08px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            >
              <InfluencerSmallCard
                avatar="block1-avatar-04.svg"
                name="Jane Cooper"
                user="@jane-cooper"
              />
            </Box>
            <Box
              sx={{
                backgroundImage: 'url("images/influencers/block1-12.svg")',
                width: '345.44px',
                height: '419.54px',
                borderRadius: '20px',
                backgroundSize: 'cover',
              }}
            >
              <InfluencerSmallCard
                avatar="block1-avatar-08.svg"
                name="Jenny Wilson"
                user="@jenny-wilson"
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '-213px',
            left: 0,
            backgroundImage:
              'linear-gradient(180deg, #222222 27.02%, rgba(34, 34, 34, 0) 100%)',
            width: '100%',
            height: '1658px',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            top: '78px',
            left: '50%',
            transform: 'translateX(-50%)',
            paddingX: { xs: '20px', md: 0 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: { xs: '335px', sm: '560px', md: '700px', lg: '854px' },
              marginX: 'auto',
            }}
          >
            <HeaderGiliamsH71 textAlign="center">
              Attract more gueﬆs to clients with Influencer marketing
            </HeaderGiliamsH71>
          </Box>

          <Box>
            <ParagraphGillC2H19 textAlign="center">
              Get your restaurant seen by more people and increase ROI with
              influencer marketing for hospitality.
            </ParagraphGillC2H19>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default InfluencersBlock1;
