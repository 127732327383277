import React from 'react';
import { Typography } from '@mui/material';

const HeaderGillO43H19 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '19px',
        fontWeight: 400,
        lineHeight: '30.97px',
        color: 'rgba(255,255,255,0.43)',
        textDecoration: 'none',
        textTransform: 'none',
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGillO43H19;
