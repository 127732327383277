import React from 'react';
import { Box } from '@mui/material';
import Circle from '../basic/Circle';
import { Color6 } from '../../colors/Colors';
import HeaderGillC7H22 from '../typography/HeaderGillC7H22';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';

const HowItWorksBlock5 = () => {
  return (
    <Box
      sx={{
        maxWidth: '934.91px',
        marginX: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: { xs: '20px', md: '2px' },
        paddingX: { xs: '20px', lg: 0 },
        flexDirection: { xs: 'column-reverse', md: 'row' },
        columnGap: '20px',
        rowGap: '40px',
      }}
    >
      <Box sx={{ maxWidth: '431px', width: { xs: '100%', sm: '431px' } }}>
        <img
          src="images/how-it-works/block5-promotions.svg"
          alt="Product Promotions"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>

      <Box sx={{ width: '300px' }}>
        <Box sx={{ marginBottom: '15px' }}>
          <Circle color={Color6} diameter="52px">
            <HeaderGillC7H22>04</HeaderGillC7H22>
          </Circle>
        </Box>

        <HeaderGiliamsC2H33>Product Promotions</HeaderGiliamsC2H33>

        <ParagraphGillC2H19>
          Get started in a flash with HYYPP. Our platform streamlines the
          influencer search process, connecting you with the perfect match in
          under 60 seconds. All you need to do is specify your food or product,
          target location, and desired audience, and HYYPP will do the rest.
        </ParagraphGillC2H19>
      </Box>
    </Box>
  );
};

export default HowItWorksBlock5;
