import React from 'react';
import { Box } from '@mui/material';
import Wrapper from '../basic/Wrapper';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';

const PricingBlock1 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          backgroundImage: 'linear-gradient(180deg, #121212 0%, #222222 100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '456px',
          width: '100%',
          zIndex: '-1',
        }}
      />
      <Box sx={{ paddingX: { xs: '20px', lg: 0 } }}>
        <Box sx={{ marginTop: { xs: '40px', sm: '74px' } }}>
          <HeaderGiliamsH71 textAlign="center">Pricing</HeaderGiliamsH71>
        </Box>
        <Box sx={{ maxWidth: '1010px', marginX: 'auto', marginTop: '20px' }}>
          <ParagraphGillC2H19 textAlign="center">
            A cost-effective plan to run your own promotions. Work with local
            nano/micro influencers. Run unlimited and multiple promotions. Paid
            Plan includes two businesses/brands for your account.
          </ParagraphGillC2H19>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default PricingBlock1;
