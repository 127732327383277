import React from 'react';
import { Box } from '@mui/material';
import HeaderGiliamsH43 from '../typography/HeaderGiliamsH43';
import HeaderGiliamsH29 from '../typography/HeaderGiliamsH29';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import HeaderGillO43H19 from '../typography/HeaderGillO43H19';
import RoundedOutlinedButton from './RoundedOutlinedButton';

const PriceCard = ({ title, price, period, itemsArr, bntTitle, btnLink }) => {
  return (
    <Box
      sx={{
        width: '337px',
        height: '499px',
        backgroundColor: 'rgba(49, 49, 49, 0.82)',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: '48px',
      }}
    >
      <Box>
        <HeaderGiliamsH43>{title}</HeaderGiliamsH43>
      </Box>

      <Box sx={{ marginTop: '23px' }}>
        <HeaderGiliamsH29>{price}</HeaderGiliamsH29>
      </Box>

      <Box sx={{ marginTop: '-2px', marginBottom: '1px' }}>
        <HeaderGillO43H19>{period}</HeaderGillO43H19>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        {itemsArr.map((item, index) => (
          <Box key={`item-${index + 1}`} sx={{ marginTop: '-1px' }}>
            <ParagraphGillC2H19 textAlign="center">{item}</ParagraphGillC2H19>
          </Box>
        ))}
      </Box>

      <Box sx={{ marginTop: '31px' }}>
        <RoundedOutlinedButton link={btnLink}>{bntTitle}</RoundedOutlinedButton>
      </Box>
    </Box>
  );
};

export default PriceCard;
