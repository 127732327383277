import React from 'react';
import { Box } from '@mui/material';
import Wrapper from '../basic/Wrapper';
import HeaderGiliamsH43 from '../typography/HeaderGiliamsH43';
import { Color6 } from '../../colors/Colors';
import HeaderGillC7H22 from '../typography/HeaderGillC7H22';
import Circle from '../basic/Circle';
import HeaderGillC1H22 from '../typography/HeaderGillC1H22';
import Chip from '../basic/Chip';
import HeaderGillH20 from '../typography/HeaderGillH20';

const CollabsBlock3 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: 'center',
          maxWidth: '1142px',
          marginX: 'auto',
          marginTop: '40px',
          columnGap: '20px',
          rowGap: '40px',
          flexWrap: { xs: 'wrap', md: 'nowrap' },
          flexDirection: { xs: 'column-reverse', md: 'row' },
          paddingX: { xs: '20px', lg: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '26px' }}>
          <Box sx={{ maxWidth: '504px' }}>
            <HeaderGiliamsH43>For Businesses and Brands</HeaderGiliamsH43>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              maxWidth: '479px',
            }}
          >
            <Box>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>01</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box sx={{ maxWidth: '360px' }}>
              <HeaderGillC1H22>
                Request campaign insights from influencers and collaborators
              </HeaderGillC1H22>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              maxWidth: '479px',
            }}
          >
            <Box>
              <Circle color={Color6} diameter="52px">
                <HeaderGillC7H22>02</HeaderGillC7H22>
              </Circle>
            </Box>
            <Box sx={{ maxWidth: '412px' }}>
              <HeaderGillC1H22>
                Gain valuable data, metrics and insight on your collaboration's
                and campaigns
              </HeaderGillC1H22>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ maxWidth: { xs: '100%', lg: '408px' }, position: 'relative' }}
        >
          <img
            src="images/collabs/block3-brands.png"
            alt="Brands"
            style={{ width: '100%', height: '100%' }}
          />

          <Box
            sx={{
              position: 'absolute',
              top: '65px',
              bottom: '',
              left: '-59px',
              right: '',
              backgroundColor: Color6,
              padding: '16px 24px 10px 23px',
              borderRadius: '70px',
              display: { xs: 'flex', sm: 'flex' },
              marginLeft: '43px',
            }}
          >
            <Box sx={{ width: '30px', overflow: 'hidden', marginTop: '' }}>
              <svg
                width="23"
                height="27"
                viewBox="0 0 23 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.105 0.842773C11.5771 0.842773 11.1268 0.921557 10.7881 1.0097C10.2381 1.15281 9.93822 1.66166 9.92719 2.17375L9.56523 19.0141V19.0202C9.56523 20.1296 8.67692 21.0236 7.58798 21.0236C6.49904 21.0236 5.61074 20.1296 5.61074 19.0202C5.61074 18.4307 5.82224 17.9864 6.14989 17.6671C6.48239 17.3431 6.94921 17.1344 7.47205 17.0539C7.8748 16.992 8.24892 16.6713 8.27018 16.2121L8.42982 12.7679C8.45181 12.2927 8.06858 11.8713 7.56762 11.9062C3.80584 12.1678 0.53125 14.8613 0.53125 19.0202C0.53125 22.9577 3.68761 26.1553 7.58798 26.1553C11.4884 26.1553 14.6448 22.9577 14.6448 19.0202L14.4609 10.4668C16.224 11.626 18.7543 12.3113 21.0928 12.4292C21.618 12.4557 22.2187 12.2148 22.3677 11.5938C22.4269 11.347 22.4688 11.0343 22.4688 10.6433C22.4688 10.2524 22.4269 9.93961 22.3677 9.69295C22.2187 9.0715 21.6156 8.85494 21.1405 8.79959C19.5118 8.60975 17.9143 7.7471 16.6654 6.51972C15.4163 5.29212 14.5371 3.72041 14.3195 2.13911C14.254 1.66298 13.9748 1.15361 13.4218 1.0097C13.0831 0.921557 12.6328 0.842773 12.105 0.842773Z"
                  fill="#828282"
                />
              </svg>
            </Box>

            <Box sx={{ marginLeft: '4px', marginBottom: '1px' }}>
              <HeaderGillH20>73K Followers</HeaderGillH20>
            </Box>
          </Box>
          {/* <Chip
            image="collabs-block2-followers2"
            alt="73K Followers"
            top="65px"
            left="-59px"
          /> */}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default CollabsBlock3;
