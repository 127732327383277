import { configureStore } from '@reduxjs/toolkit';
import signInReducer from './signIn';

const store = configureStore({
  reducer: {
    signIn: signInReducer,
  },
});

export default store;
