import React from 'react';
import Wrapper from './basic/Wrapper';
import InfluencerCard from './basic/InfluencerCard';
import { Box } from '@mui/material';
import HeaderGiliamsH71 from './typography/HeaderGiliamsH71';

const InfluencersSection = () => {
  return (
    <Wrapper>
      <Box sx={{ marginTop: { xs: '50px', sm: '100px', md: '136px' } }}>
        <HeaderGiliamsH71 textAlign="center">
          The <span style={{ color: '#EAC67A' }}> love </span> is real
        </HeaderGiliamsH71>
      </Box>

      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          height: '243px',
          marginX: 'auto',
          marginTop: '46px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '25px',
            position: 'absolute',
            top: 0,
            left: '-89px',
          }}
        >
          <InfluencerCard
            stars={5}
            review="I've been trying this new influencer platform for the past few days and I must say, it's quickly becoming one of my favorites. The community is super friendly and supportive. #lovethevibe"
            avatar="avatar-01.svg"
            name="Fred Valdez"
            type="Facebook review"
          />

          <InfluencerCard
            stars={5}
            review="I'm loving the HYYPP platform! The user interface is clean and user-friendly, and I love the ability to connect with my followers in real time. #loveit"
            avatar="avatar-01.svg"
            name="Bradley Moore"
            type="Instagram review"
          />

          <InfluencerCard
            stars={5}
            review="Just tried out this new HYYPP influencer platform and I must say, I'm impressed! The level of engagement and interaction I've received has been amazing. #newfavorite"
            avatar="avatar-02.svg"
            name="Andre Gregory"
            type="Youtube review"
          />

          <InfluencerCard
            stars={5}
            review="If you are going to use a passage of Lorem Ipsum, you need to be sure are things there isn't anything embarrassing hidden in the middle of text."
            avatar="avatar-03.svg"
            name="Shawn Lyons"
            type="Facebook review"
          />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          height: '268px',
          marginX: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '25px',
            position: 'absolute',
            top: 0,
            left: '-340px',
            marginTop: '25px',
          }}
        >
          <InfluencerCard
            stars={5}
            review="The HYYPP influencer social platform is a total love-changer! The customization options for my profile and the ease of use make it stand out from the rest. Highly recommend checking it out. #amazing #musttry"
            avatar="avatar-01.svg"
            name="Fred Valdez"
            type="Facebook review"
          />

          <InfluencerCard
            stars={5}
            review="The HYYPP influencer social platform is a breath of fresh air! The privacy features are a huge plus and the speed of the platform is lightning fast. #amazing"
            avatar="avatar-04.svg"
            name="Nell Mendez"
            type="Facebook review"
          />

          <InfluencerCard
            stars={5}
            review="I never thought I'd find an influencer platform that would make me want to switch from my current one, but this new platform has done just that! #brilliant #love #earn"
            avatar="avatar-05.svg"
            name="Cody Gomez"
            type="TikTok review"
          />

          <InfluencerCard
            stars={5}
            review="The platform is a total game-changer! The customization options for my profile and the ease of use make it stand out from the rest. Highly recommend checking it out."
            avatar="avatar-06.svg"
            name="Delia Christensen"
            type="Instagram review"
          />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default InfluencersSection;
