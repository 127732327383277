import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGiliamsH51 = ({ children, textAlign }) => {
  return (
    <Typography
      component="h3"
      sx={{
        fontFamily: 'Giliams',
        fontSize: { xs: '41px', sm: '46px', lg: '51px' },
        fontWeight: 400,
        lineHeight: { xs: '46px', sm: '51px', md: '56px' },
        color: Color2,
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGiliamsH51;
