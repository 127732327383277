// noinspection JSValidateTypes

import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import RoundedOutlinedButton from '../basic/RoundedOutlinedButton';

const faqArr = [
  {
    title: 'What platforms does HYYPP work with?',
    description:
      'HYYPP works with any platform you choose, including YouTube, Instagram, Twitter, Facebook, and more.',
  },
  {
    title: 'Can I create a promotion for more than one type of product?',
    description:
      "Yes, you can create a promotion for multiple products. You'll reach a wide variety of micro-influencers with each promotion; the exact number depends on the criteria that you specify in the promotion.",
  },
  {
    title: 'How many micro-influencers will I reach with each promotion?',
    description:
      'Micro-influencers are individuals with followers ranging from 1k to 50k, who boast a highly engaged following within a specific niche. HYYPP bridges the gap between brands and local influencers to aid in product or service promotion. Promotions through HYYPP vary in nature and typically yield an audience conversion rate of 1.5% to 5% on average.',
  },
  {
    title:
      'Do I need to have a certain number of followers to be eligible to participate in a promotion as a brand?',
    description:
      'No, any legitimate brand is eligible to participate in a promotion regardless of the number of followers they have. You will be able to reach thousands of local micro-influencers with each promotion to help grow your brand or product awareness.',
  },

  {
    title: "What if I don't have any products to give away?",
    description:
      'You can use HYYPP to promote your brand in many ways including cash giveaways, vouchers, discounts, or experiences.',
  },
];

const PricingBlock3 = () => {
  return (
    <Box
      sx={{
        maxWidth: '1440px',
        backgroundColor: 'rgba(49, 49, 49, 0.82)',
        marginX: 'auto',
        marginTop: '120px',
        display: 'flex',
        flexDirection: ' column',
        alignItems: 'center',
        paddingX: { xs: '20px', lg: 0 },
      }}
    >
      <Box sx={{ marginTop: '101px' }}>
        <HeaderGiliamsH71 textAlign="center">FAQ’s</HeaderGiliamsH71>
      </Box>

      <Box sx={{ maxWidth: '1142px', marginX: 'auto', marginTop: '44px' }}>
        {faqArr.map((item, index) => (
          <Accordion
            key={`accordion-${index + 1}`}
            sx={{
              backgroundColor: '#2e2e2e',
              border: 'none',
              boxShadow: 'none',
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  width="27"
                  height="24"
                  viewBox="0 0 27 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="1"
                    y1="12"
                    x2="26"
                    y2="12"
                    stroke="#EAC67A"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <line
                    x1="14"
                    y1="1"
                    x2="14"
                    y2="23"
                    stroke="#EAC67A"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              }
              aria-controls={`panel-${index + 1}-content`}
              id={`panel-${index + 1}-header`}
              sx={{
                backgroundColor: '#2e2e2e',
                border: 'none',
                borderBottom: '1px solid rgba(255, 255, 255, 0.07)',
                boxShadow: 'none',
                paddingY: '10px',
              }}
            >
              <Box>
                <HeaderGiliamsC2H33>{item.title}</HeaderGiliamsC2H33>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <ParagraphGillC2H19>{item.description}</ParagraphGillC2H19>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Box sx={{ marginTop: '50px', marginBottom: '100px' }}>
        <RoundedOutlinedButton link="/faqs">
          <Box sx={{ paddingX: '9px' }}>More FAQ’s</Box>
        </RoundedOutlinedButton>
      </Box>
    </Box>
  );
};

export default PricingBlock3;
