// Gold (Crayola)
export const Color1 = '#EBCA7E';

// White
export const Color2 = '#FFF';

// Naples Yellow
export const Color3 = '#FEC75B';

// Raisin Black
export const Color4 = '#222222';

// Bright Gray
export const Color5 = '#EBEBEB';

// Dark Charcoal
export const Color6 = '#2E2E2E';

// Gold (Crayola)
export const Color7 = '#EAC67A';
export const Color8 = '#373737';
