import React from 'react';
import { Box } from '@mui/material';
import HeaderGiliamsH71 from '../typography/HeaderGiliamsH71';
import HeaderGillC2H19Italic from '../typography/HeaderGillC2H19Italic';
import RoundedButton from '../basic/RoundedButton';

const Block6 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px',
        flexWrap: 'wrap',
        maxWidth: '1156px',
        marginX: 'auto',
        marginTop: '94px',
        marginBottom: { xs: '10px', lg: '-77px' },
        paddingX: { xs: '24px', lg: '0px' },
      }}
    >
      <Box sx={{ marginX: 'auto' }}>
        <HeaderGiliamsH71 textAlign={{ xs: 'center', lg: 'left' }}>
          Built for <span style={{ color: '#EAC67A' }}> Brands </span>, powered
          by<span style={{ color: '#EAC67A' }}> Creators</span>
        </HeaderGiliamsH71>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-end' },
          alignItems: 'center',
          gap: '15px',
          marginTop: '-15px',
          flexWrap: 'wrap',
        }}
      >
        <HeaderGillC2H19Italic textAlign={{ xs: 'center', lg: 'left' }}>
          Go Viral. An amazing creator campaign is just a click away!
        </HeaderGillC2H19Italic>

        <RoundedButton link={process.env.REACT_APP_GET_STARTED}>
          Let’s Get Started
        </RoundedButton>
      </Box>
    </Box>
  );
};

export default Block6;
