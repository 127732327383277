import React from 'react';
import { Box } from '@mui/material';
import Wrapper from '../basic/Wrapper';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';

const InfluencersBlock4 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: { xs: '100%', lg: '1175px' },
          marginX: 'auto',
          marginTop: { xs: '100px', md: '150px' },
          paddingX: { xs: '20px', lg: 0 },
          rowGap: '20px',
          columnGap: '40px',
          flexDirection: { xs: 'column', md: 'row' },
          marginBottom: { xs: '50px', sm: '150px', lg: 0 },
        }}
      >
        <Box>
          <Box sx={{ maxWidth: { xs: '100%', lg: '398px' } }}>
            <HeaderGiliamsC2H33>Target specific diners</HeaderGiliamsC2H33>
          </Box>

          <Box
            sx={{
              maxWidth: { xs: '100%', lg: '492px' },
              marginTop: { xs: '20px', lg: '6px' },
              marginBottom: { xs: '20px', lg: 0 },
            }}
          >
            <ParagraphGillC2H19>
              Vegan eateries in Dubai don’t want to advertise to meat-lovers in
              London. Influencers attract followers just like them. By
              collaborating with the right influencers you can extend your
              brands reach, finding the patrons who will dine at your restaurant
              and possibly fall in love with your brand .
            </ParagraphGillC2H19>
          </Box>
        </Box>
        <Box
          sx={{ marginRight: { xs: 0, md: '34px' }, minWidth: { sm: '446px' } }}
        >
          <img
            src="images/influencers/block4-diners.svg"
            alt="Top Countries"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default InfluencersBlock4;
