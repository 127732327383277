import React from 'react';
import { Box } from '@mui/material';
import HeaderGiliamsH43 from '../typography/HeaderGiliamsH43';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import { Color7 } from '../../colors/Colors';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

const Block4 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        columnGap: '20px',
        rowGap: '40px',
        justifyContent: { xs: 'center', md: 'space-between' },
        alignItems: 'center',
        width: { xs: '100%', lg: '1140px' },
        paddingX: { xs: '20px', lg: 0 },
        marginX: 'auto',
        marginTop: { xs: '80px', lg: '142px' },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
      }}
    >
      <Box sx={{ maxWidth: '435px' }}>
        <img
          src="images/block4-sales.svg"
          alt="Sales Results"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Box sx={{ maxWidth: { xs: '400px', lg: '472px' } }}>
        <HeaderGiliamsH43>Hey Brands! Get huge</HeaderGiliamsH43>
        <HeaderGiliamsH43>traffic from influencers</HeaderGiliamsH43>

        <Box sx={{ marginTop: '16px' }}>
          <ParagraphGillC2H19>
            Hit marketing goals at a fraction of your budget:
          </ParagraphGillC2H19>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '18px',
            marginTop: '32px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <CheckCircleSharpIcon sx={{ color: Color7 }} />
            <ParagraphGillC2H19>
              Pay influencers in exchange for product from your store
            </ParagraphGillC2H19>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <CheckCircleSharpIcon sx={{ color: Color7 }} />
            <ParagraphGillC2H19>
              Up to 10X better results than traditional marketing
            </ParagraphGillC2H19>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <CheckCircleSharpIcon sx={{ color: Color7 }} />
            <ParagraphGillC2H19>
              Use real people instead of ad platforms
            </ParagraphGillC2H19>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Block4;
