import React from 'react';
import { Box } from '@mui/material';
import Wrapper from '../basic/Wrapper';
import HeaderGiliamsH81 from '../typography/HeaderGiliamsH81';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import ScrollDown from '../basic/ScrollDown';
import './Block1.css';
import AppsButtons from '../AppsButtons';

const Block1 = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: { xs: 'space-around', lg: 'space-between' },
          alignItems: 'center',
          marginTop: { xs: '30px', sm: '60px', lg: '140px' },
          marginX: 'auto',
          width: { xs: '94%', lg: '1144px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '61px',
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: '100%', lg: '522px' },
            }}
          >
            <HeaderGiliamsH81 textAlign={{ xs: 'center', lg: 'left' }}>
              Find Amazing
              <span style={{ color: '#EAC67A' }}> Influencers </span>
              for your
              <span style={{ color: '#EAC67A' }}> Brand </span>
              in 60 seconds.
            </HeaderGiliamsH81>
          </Box>

          <Box
            sx={{
              maxWidth: { xs: '100%', lg: '450px' },
              marginBottom: '22px',
              paddingY: { xs: '30px', lg: '0px' },
            }}
          >
            <ParagraphGillC2H19 textAlign={{ xs: 'center', lg: 'left' }}>
              Connect with amazing. Instagram, TikTok, Youtube etc. creators to
              unleash the Power of Influencer Marketing.
            </ParagraphGillC2H19>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <AppsButtons />
          </Box>
        </Box>

        <Box
          sx={{
            marginRight: { xs: '-50px', lg: '30px' },
            marginTop: { xs: '0px', md: '50px' },
            backgroundImage: 'url(images/block1-hero-1.svg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: { xs: '240px', sm: '443px' },
            height: { xs: '326px', sm: '601px' },
            display: 'block',
            position: 'relative',
          }}
        >
          <img
            src="images/block1-hero-1.png"
            alt="Influencers"
            style={{
              borderRadius: '300px',
              position: 'absolute',
              top: '-20px',
              left: '-24px',
              width: '100%',
              height: '100%',
            }}
          />

          {/*<Box*/}
          {/*  sx={{*/}
          {/*    position: 'absolute',*/}
          {/*    top: { xs: '-40px', sm: '-60px' },*/}
          {/*    left: { xs: '-60px', sm: '-80px' },*/}
          {/*    width: { xs: '312px', sm: '549px' },*/}
          {/*    height: { xs: '203px', sm: '357px' },*/}
          {/*    background: {*/}
          {/*      xs: 'url("images/social-icons-xs.png")',*/}
          {/*      sm: 'url("images/social-icons.png")',*/}
          {/*    },*/}
          {/*    backgroundSize: 'contain',*/}
          {/*    backgroundRepeat: 'no-repeat',*/}
          {/*  }}*/}
          {/*/>*/}

          <img
            className="twitter-icon"
            src="images/twitter-icon.png"
            alt="Twitter"
          />

          <img
            className="facebook-icon"
            src="images/facebook-icon.png"
            alt="Facebook"
          />

          <img
            className="tiktok-icon"
            src="images/tiktok-icon.png"
            alt="Tiktok"
          />

          <img
            className="youtube-icon"
            src="images/youtube-icon.png"
            alt="Youtube"
          />

          <img
            className="instagram-icon"
            src="images/instagram-icon.png"
            alt="Instagram"
          />
        </Box>
      </Box>

      <Box sx={{ marginTop: '50px', display: { xs: 'block', md: 'none' } }}>
        <AppsButtons />
      </Box>

      <Box
        sx={{
          marginX: 'auto',
          maxWidth: '100px',
          display: { xs: 'none', md: 'block' },
          marginTop: '33px',
        }}
      >
        <ScrollDown />
      </Box>
    </Wrapper>
  );
};

export default Block1;
