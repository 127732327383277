// noinspection JSValidateTypes

import React from 'react';
import { Box } from '@mui/material';
import HeaderGiliamsH43 from '../typography/HeaderGiliamsH43';
import HeaderGillC1H22 from '../typography/HeaderGillC1H22';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';

const Block5 = () => {
  const itemsArr = [
    {
      title: 'Complete control',
      description:
        'Find, arrange and track influencer visits all in one place.',
    },
    {
      title: 'Clear communication',
      description:
        'No more no shows. Sites and influencers know what’s happening and when.',
    },
    {
      title: 'Track results',
      description:
        'Choose influencers based on performance and measure the results.',
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '20px',
        rowGap: '40px',
        justifyContent: { xs: 'center', md: 'space-between' },
        alignItems: 'center',
        width: { xs: '100%', lg: '1140px' },
        paddingX: { xs: '20px', lg: 0 },
        marginX: 'auto',
        marginTop: { xs: '50px', md: '144px' },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ maxWidth: { xs: '370px', lg: '435px' } }}>
          <HeaderGiliamsH43>
            Every booking runs smoothly, everytime
          </HeaderGiliamsH43>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '26px',
            marginTop: '16px',
          }}
        >
          {itemsArr.map((item, index) => (
            <Box
              key={`box-item-${index + 1}`}
              sx={{ maxWidth: { xs: '370px', lg: '430px' } }}
            >
              <Box>
                <HeaderGillC1H22>{item.title}</HeaderGillC1H22>
              </Box>

              <ParagraphGillC2H19>{item.description}</ParagraphGillC2H19>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ maxWidth: '450.56px' }}>
        <img
          src="images/block5-booking.svg"
          alt="Every booking runs smoothly"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default Block5;
