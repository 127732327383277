import React from 'react';
import { Box } from '@mui/material';
import Circle from '../basic/Circle';
import { Color6 } from '../../colors/Colors';
import HeaderGillC7H22 from '../typography/HeaderGillC7H22';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';

const HowItWorksBlock3 = () => {
  return (
    <Box
      sx={{
        maxWidth: '922.41px',
        marginX: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '46px',
        paddingX: { xs: '20px', lg: 0 },
        flexDirection: { xs: 'column-reverse', md: 'row' },
        columnGap: '20px',
        rowGap: '40px',
      }}
    >
      <Box sx={{ maxWidth: '429px', width: { xs: '100%', sm: '429px' } }}>
        <img
          src="images/how-it-works/block3-influencers.svg"
          alt="Influencers Apply"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>

      <Box sx={{ width: '300px' }}>
        <Box sx={{ marginBottom: '15px' }}>
          <Circle color={Color6} diameter="52px">
            <HeaderGillC7H22>02</HeaderGillC7H22>
          </Circle>
        </Box>

        <HeaderGiliamsC2H33>Influencers Apply</HeaderGiliamsC2H33>

        <ParagraphGillC2H19>
          Say goodbye to the time-consuming task of manual outreach to
          influencers. Simply let interested local influencers approach you,
          allowing you to sit back and relax while they apply to collaborate
          with your brand.
        </ParagraphGillC2H19>
      </Box>
    </Box>
  );
};

export default HowItWorksBlock3;
