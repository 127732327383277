import React from 'react';
import { Typography } from '@mui/material';
import { Color2 } from '../../colors/Colors';

const HeaderGillC2H17 = ({ children, textAlign }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Gill Sans", sans-serif',
        fontSize: '17px',
        fontWeight: 500,
        fontStyle: 'normal',
        lineHeight: '26px',
        color: Color2,
        textDecoration: 'none',
        textTransform: 'none',
        textAlign: textAlign ? textAlign : 'left',
      }}
    >
      {children}
    </Typography>
  );
};

export default HeaderGillC2H17;
