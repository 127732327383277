// Empty fields
export const emptyFirstName = {
  message: 'Error: field First name is required.',
  variant: 'error',
};

export const emptyCountryName = {
  message: 'Error: field country is required.',
  variant: 'error',
};

export const emptyBirthdate = {
  message: 'Error: field birth date is required.',
  variant: 'error',
};

export const emailFormat = {
  message: 'Error: Invalid Email Address.',
  variant: 'error',
};

export const emptyEmailAddress = {
  message: 'Error: field Email Address is required.',
  variant: 'error',
};

export const emptyMobileNumber = {
  message: 'Error: field Mobile Number is required.',
  variant: 'error',
};

export const emptyInstagramUrl = {
  message: 'Error: Invalid instagram url.',
  variant: 'error',
};

export const emptyFacebookUrl = {
  message: 'Error: Invalid Facebook url.',
  variant: 'error',
};

export const emptyWebsiteUrl = {
  message: 'Error: Invalid website url.',
  variant: 'error',
};

export const validBirthOfDate = {
  message: 'Error: Date of birth must be at least 13 years old.',
  variant: 'error',
};

// Wrong format
export const invalidEmailAddress = {
  message: 'Warning: invalid Email Address.',
  variant: 'warning',
};

export const invalidMobileNumber = {
  message: 'Warning: invalid Mobile Number.',
  variant: 'warning',
};

// Terms, Privacy Policy
export const mustAccept = {
  message: 'Warning: You must accept the Terms and Privacy Policy.',
  variant: 'warning',
};
export const SubmitCatch = {
  message: 'Error: something went wrong !.',
  variant: 'error',
};
export const SubmitSuccess = {
  message: 'Form Submited successfully.',
  variant: 'reportComplete',
};

export const SubmitError = {
  message: 'Internal Server Error !.',
  variant: 'error',
};
