// noinspection JSValidateTypes

import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import HeaderGiliamsC2H33 from '../typography/HeaderGiliamsC2H33';
import ParagraphGillC2H19 from '../typography/ParagraphGillC2H19';
import HeaderGiliamsH43 from '../typography/HeaderGiliamsH43';

const FAQsBlock2 = () => {
  const faqArr = [
    {
      title: 'What platforms does HYYPP work with?',
      description:
        'HYYPP works with any platform you choose, including YouTube, Instagram, Twitter, Facebook, and more.',
    },
    {
      title: 'Can I create a promotion for more than one type of product?',
      description:
        "Yes, you can create a promotion for multiple products. You'll reach a wide variety of micro-influencers with each promotion; the exact number depends on the criteria that you specify in the promotion.",
    },
    {
      title: 'How many micro-influencers will I reach with each promotion?',
      description:
        'A micro-influencer is an influencer wih anywhere from 1k - 50k followers who has a highly engaged audience within their niche.. HYYPP works by connecting brands with local influencers in order to help promote products or services.',
    },
    {
      title:
        'Do I need to have a certain number of followers to be eligible to participate in a promotion?',
      description:
        'No, any influencer is eligible to participate in a promotion regardless of the number of followers they have. You will be able to reach thousands of local micro-influencers with each promotion.',
    },

    {
      title: "What if I don't have any products to give away?",
      description:
        'You can use HYYPP to promote your brand in many ways including giveaways, discounts or shoutouts.',
    },
    {
      title:
        'Can I use HYYPP to promote my brand outside of the food industry?',
      description:
        'Yes, HYYPP can be used to promote any business or brand outside of the food industry.',
    },
    {
      title: 'What is a micro-influencer?',
      description:
        'HYYPP helps connect brands and businesses to local influencers by enabling them to create promotions that influencers can apply for.',
    },
    {
      title: 'How does HYYPP work?',
      description:
        'Simply go to your dashboard and add your business under “brand” tab then add product & services related with it. Yes, hypp have quality influencer for every industry we serve!',
    },
    {
      title: 'How do i add more business or brands',
      description:
        'You can use HYYPP to promote your brand in many ways including giveaways, discounts or shoutouts. To add more business or brands, first log into your account and click on “Add Business” from the left-hand menu.',
    },
    {
      title: 'Does hypp have quality influencer',
      description:
        'Yes, hypp does have quality influencers that are handpicked based on their engagement rate, reach and demographic fit. ',
    },
    {
      title: 'What Countries are supported?',
      description:
        'Currently we support USA, UK and most European countries as well as Australia and New Zealand. You can create unlimited Discounts & Promotions using HYYPP!',
    },
    {
      title: 'How many Discounts & promotions can 1 create?',
      description:
        'Yes, you can create a promotion for multiple products at once. Each promotion reaches thousands of qualified micro-influencers who have a combined reach of tens of millions of people.',
    },
  ];

  const faqForBrandsArr = [
    {
      title: 'How do I create a promotion on HYYPP?',
      description:
        'To create a promotion on HYYPP, simply log into your account and select "Create a Promotion." Fill out the required details and offer, then select the local influencers you would like to work with.',
    },
    {
      title: 'How do I select local influencers to work with?',
      description:
        'Interested influencers will apply to your promotion, and you can select the influencer(s) you would like to work with through the HYYPP app dashboard.',
    },
    {
      title: 'How do I chat with selected influencers?',
      description:
        'You can chat with selected influencers directly through the HYYPP app.',
    },
    {
      title:
        'How do I coordinate product delivery or pick-up with influencers?',
      description:
        "Coordinate product delivery or pick-up with influencers through the HYYPP app's chat feature.",
    },
    {
      title: 'Can I track the progress of my promotion on HYYPP?',
      description:
        "Yes, you can track the progress of your promotion and upcoming visits in the HYYPP app's dashboard.",
    },
  ];

  const faqForInfluencersArr = [
    {
      title: 'How do I apply to collaborate with a brand on HYYPP?',
      description:
        'To apply to collaborate with a brand on HYYPP, simply log into your account and select the desired promotion. Fill out the required information and submit your application.',
    },
    {
      title: 'How do I chat with brands?',
      description: 'You can chat with brands directly through the HYYPP app.',
    },
    {
      title: 'How do I redeem vouchers or receive products from brands?',
      description:
        "Coordinate voucher redemption or product delivery/pick-up with brands through the HYYPP app's chat feature.\n",
    },
    {
      title: 'How do I create content for a brand promotion?',
      description:
        'You will create content for the brand promotion after redeeming the voucher or receiving the product.',
    },
    {
      title:
        'Can I track the progress of my collaboration with a brand on HYYPP?',
      description:
        'Yes, you can track the progress of your collaboration with a brand in the HYYPP app.',
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          maxWidth: '1142px',
          marginX: 'auto',
          marginTop: { xs: '40px', sm: '106px' },
          marginBottom: '130px',
          paddingX: { xs: '20px', lg: 0 },
        }}
      >
        <Box>
          {faqArr.map((item, index) => (
            <Accordion
              key={`accordion-${index + 1}`}
              sx={{
                backgroundColor: 'rgba(46,46,46,0)',
                border: 'none',
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                expandIcon={
                  <svg
                    width="27"
                    height="24"
                    viewBox="0 0 27 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="1"
                      y1="12"
                      x2="26"
                      y2="12"
                      stroke="#EAC67A"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <line
                      x1="14"
                      y1="1"
                      x2="14"
                      y2="23"
                      stroke="#EAC67A"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                }
                aria-controls={`panel-${index + 1}-content`}
                id={`panel-${index + 1}-header`}
                sx={{
                  backgroundColor: 'rgba(46,46,46,0)',
                  border: 'none',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.07)',
                  boxShadow: 'none',
                  paddingY: '10px',
                }}
              >
                <Box>
                  <HeaderGiliamsC2H33>{item.title}</HeaderGiliamsC2H33>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <ParagraphGillC2H19>{item.description}</ParagraphGillC2H19>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '80px',
            marginBottom: '40px',
          }}
        >
          <HeaderGiliamsH43>For Brands:</HeaderGiliamsH43>
        </Box>

        <Box>
          {faqForBrandsArr.map((item, index) => (
            <Accordion
              key={`accordion-${index + 1}`}
              sx={{
                backgroundColor: 'rgba(46,46,46,0)',
                border: 'none',
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                expandIcon={
                  <svg
                    width="27"
                    height="24"
                    viewBox="0 0 27 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="1"
                      y1="12"
                      x2="26"
                      y2="12"
                      stroke="#EAC67A"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <line
                      x1="14"
                      y1="1"
                      x2="14"
                      y2="23"
                      stroke="#EAC67A"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                }
                aria-controls={`panel-${index + 1}-content`}
                id={`panel-${index + 1}-header`}
                sx={{
                  backgroundColor: 'rgba(46,46,46,0)',
                  border: 'none',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.07)',
                  boxShadow: 'none',
                  paddingY: '10px',
                }}
              >
                <Box>
                  <HeaderGiliamsC2H33>{item.title}</HeaderGiliamsC2H33>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <ParagraphGillC2H19>{item.description}</ParagraphGillC2H19>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '80px',
            marginBottom: '40px',
          }}
        >
          <HeaderGiliamsH43>For Influencers:</HeaderGiliamsH43>
        </Box>

        <Box>
          {faqForInfluencersArr.map((item, index) => (
            <Accordion
              key={`accordion-${index + 1}`}
              sx={{
                backgroundColor: 'rgba(46,46,46,0)',
                border: 'none',
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                expandIcon={
                  <svg
                    width="27"
                    height="24"
                    viewBox="0 0 27 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="1"
                      y1="12"
                      x2="26"
                      y2="12"
                      stroke="#EAC67A"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <line
                      x1="14"
                      y1="1"
                      x2="14"
                      y2="23"
                      stroke="#EAC67A"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                }
                aria-controls={`panel-${index + 1}-content`}
                id={`panel-${index + 1}-header`}
                sx={{
                  backgroundColor: 'rgba(46,46,46,0)',
                  border: 'none',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.07)',
                  boxShadow: 'none',
                  paddingY: '10px',
                }}
              >
                <Box>
                  <HeaderGiliamsC2H33>{item.title}</HeaderGiliamsC2H33>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <ParagraphGillC2H19>{item.description}</ParagraphGillC2H19>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FAQsBlock2;
