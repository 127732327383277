import React from 'react';
import { Box } from '@mui/material';
import FooterNavigation from './FooterNavigation';
import FooterCopyright from './FooterCopyright';

const Footer = () => {
  return (
    <Box
      sx={{
        width: { xs: '100%', lg: '1142px' },
        paddingX: { xs: '20px', lg: 0 },
        marginX: 'auto',
        marginBottom: { xs: '25px', lg: '50px' },
      }}
    >
      <FooterNavigation />
      <FooterCopyright />
    </Box>
  );
};

export default Footer;
