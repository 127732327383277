import React from 'react';
import { Box } from '@mui/material';
import { Color6 } from '../../colors/Colors';
import HeaderGillH20 from '../typography/HeaderGillH20';

const Chip = ({ image, alt, top, bottom, left, right, mt }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: top ? top : '',
        bottom: bottom ? bottom : '',
        left: left ? left : '',
        right: right ? right : '',
        backgroundColor: Color6,
        padding: '16px 24px 10px 23px',
        borderRadius: '70px',
        display: { xs: 'flex', sm: 'flex' },
        marginLeft:"43px",
      }}
    >
      <Box sx={{ width: '30px', overflow: 'hidden', marginTop: mt ? mt : '' }}>
        <img src={`images/influencers/${image}.svg`} alt={alt} />
      </Box>

      <Box sx={{ marginLeft: '4px', marginBottom: '1px' }}>
        <HeaderGillH20>{alt}</HeaderGillH20>
      </Box>
    </Box>
  );
};

export default Chip;
