import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Wrapper from '../components/basic/Wrapper';
import HeaderGiliamsH51 from '../components/typography/HeaderGiliamsH51';
import HeaderGillC2H19 from '../components/typography/HeaderGillC2H19';
import Nike from '../../src/assets/brands/nike.svg';
import Kelloggs from '../../src/assets/brands/kelloggs.svg';
import Cadbury from '../../src/assets/brands/cadbury.svg';
import KFC from '../../src/assets/brands/kfc.svg';
import Sprite from '../../src/assets/brands/sprite.svg';
import Pringles from '../../src/assets/brands/pringles.png';
import Lindt from '../../src/assets/brands/lindt.svg';
import Lays from '../../src/assets/brands/lays.png';
import InstagramSection from '../components/InstagramSection';
import { Color8 } from '../colors/Colors';
import HeaderGiliamsH35 from '../components/typography/HeaderGiliamsH35';
import RoundedButtonBig from '../components/basic/RoundedButtonBig';

const GetStarted = () => {
  useEffect(() => {
    localStorage.removeItem('type');
  }, []);
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginX: 'auto',
          marginTop: '183px',
          maxWidth: { xs: '100%', lg: '1144px' },
          paddingX: { xs: '20px', lg: '0px' },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '448px',
            top: '280px',
            left: '50%',
            transform: 'translate(-50%, 0px)',
            background: 'url("images/iphone-background.png") no-repeat center',
            zIndex: '-1',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '1464px',
            height: '448px',
            top: '280px',
            left: '50%',
            transform: 'translate(-50%, 0px)',
            background: 'radial-gradient(rgba(34,34,34,0.97) 50%, #222222 70%)',
            zIndex: '-1',
          }}
        />
        <Box
          sx={{
            backgroundImage:
              'linear-gradient(180deg, #121212 0%, #222222 100%)',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '286px',
            width: '100%',
            zIndex: '-2',
          }}
        />
        <Box>
          <HeaderGiliamsH51>
            Welcome To<span style={{ color: '#EAC67A' }}> The Future</span>
          </HeaderGiliamsH51>

          <Box sx={{ marginTop: '10px' }}>
            <HeaderGillC2H19>
              Before you apply, you will need the following:
            </HeaderGillC2H19>

            <HeaderGillC2H19>
              ● Page Admin from a Facebook Account.
            </HeaderGillC2H19>
            <HeaderGillC2H19>
              ● Business/Creator Instagram account
            </HeaderGillC2H19>
            <HeaderGillC2H19>
              ● Facebook Page connected to Instagram account
            </HeaderGillC2H19>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '21px',
              rowGap: '18px',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  width: '97px',
                  height: '82px',
                  background: `url(${Nike})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  marginRight: '24.23px',
                }}
              />

              <Box
                sx={{
                  width: '111px',
                  height: '82px',
                  background: `url(${Kelloggs})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  marginRight: '25px',
                }}
              />

              <Box
                sx={{
                  width: '112px',
                  height: '82px',
                  background: `url(${Cadbury})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  marginRight: '34px',
                }}
              />

              <Box
                sx={{
                  width: '58px',
                  height: '82px',
                  background: `url(${Pringles})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                }}
              />
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  width: '102px',
                  height: '82px',
                  background: `url(${KFC})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  marginRight: '20px',
                }}
              />

              <Box
                sx={{
                  width: '104px',
                  height: '82px',
                  background: `url(${Sprite})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  marginRight: '34px',
                }}
              />

              <Box
                sx={{
                  width: '75px',
                  height: '82px',
                  background: `url(${Lays})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  marginRight: '18px',
                }}
              />

              <Box
                sx={{
                  width: '147px',
                  height: '82px',
                  background: `url(${Lindt})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '540px',
            height: '368px',
            backgroundColor: `${Color8}`,
            borderRadius: '20px',
            paddingY: '39px',
            paddingLeft: '42px',
            paddingRight: '50px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}>
            <HeaderGiliamsH35>Let's Get Started</HeaderGiliamsH35>
            <HeaderGillC2H19>What best describes you?</HeaderGillC2H19>
          </Box>

          <Box
            sx={{ display: 'flex', flexDirection: 'column', rowGap: '18px' }}
          >
            <RoundedButtonBig
              title="Brand"
              description="Reach more customers online, go viral quickly."
            />

            <RoundedButtonBig
              title="Creator / Influencer"
              description="Access exclusive cash, experience & voucher offers"
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ marginTop: '4px', marginBottom: '-26px' }}>
        <InstagramSection />
      </Box>
    </Wrapper>
  );
};

export default GetStarted;
